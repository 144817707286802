import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { topics, subtopicComponents } from '../utils/subtopics';
import Header from '../Header';
import Footer from '../Footer';
import '../styles/Learn.css';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const Learn = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const searchTerm = searchParams.get('search') ? decodeURIComponent(searchParams.get('search')) : '';
    const initialTopic = searchParams.get('topic') ? decodeURIComponent(searchParams.get('topic')) : null;
    const initialSubtopic = searchParams.get('subtopic') ? decodeURIComponent(searchParams.get('subtopic')) : null;

    const [mainContent, setMainContent] = useState(null);
    const [topic, setTopic] = useState(initialTopic);
    const [selectedSubtopic, setSelectedSubtopic] = useState(initialSubtopic);
    const [sidebarVisible, setSidebarVisible] = useState(true);

    useEffect(() => {
        if (searchTerm) {
            const allSubtopics = Object.keys(topics).reduce((acc, topic) => {
                return acc.concat(topics[topic].map(subtopic => ({ topic, subtopic })));
            }, []);
            const foundSubtopic = allSubtopics.find(({ subtopic }) => 
                subtopic.toLowerCase().includes(searchTerm.toLowerCase())
            );
            if (foundSubtopic) {
                setTopic(foundSubtopic.topic);
                handleSubtopicClick(foundSubtopic.subtopic);
            } else {
                setMainContent(<div><h1>Search Results</h1><h3>No results found for '{searchTerm}'</h3></div>);
            }
        } else if (selectedSubtopic) {
            handleSubtopicClick(selectedSubtopic);
        } else if (initialTopic) {
            setTopic(initialTopic);
        } else {
            navigate('/');
        }
    }, [searchTerm, selectedSubtopic, initialTopic]);

    useEffect(() => {
        if (!selectedSubtopic && window.innerWidth < 769) {
            setSidebarVisible(true);
        }
    }, [selectedSubtopic]);

    const subtopics = topics[topic] || [];

    const handleSubtopicClick = (subtopicName) => {
        setSelectedSubtopic(subtopicName);
        navigate(`/learn?topic=${encodeURIComponent(topic)}&subtopic=${encodeURIComponent(subtopicName)}`);
        try {
            const SubtopicComponent = subtopicComponents[subtopicName];
            if (!SubtopicComponent) {
                throw new Error('Subtopic component not found');
            }
            setMainContent(<SubtopicComponent name={subtopicName} />);
        } catch (error) {
            setMainContent(<div>
                <h1>{subtopicName}</h1>
                <h3>Coming soon!</h3>
            </div>);
        }
        // Hide sidebar on subtopic click for mobile view
        if (window.innerWidth < 769) {
            setSidebarVisible(false);
        }
    };

    const navigateSubtopics = (direction) => {
        if (!selectedSubtopic) return;

        const currentIndex = subtopics.indexOf(selectedSubtopic);
        const newIndex = direction === 'left'
            ? currentIndex - 1
            : currentIndex + 1;

        if (newIndex >= 0 && newIndex < subtopics.length) {
            handleSubtopicClick(subtopics[newIndex]);
        }
    };

    const handleStudyTopicClick = () => {
        if (window.innerWidth < 769) {
            setSidebarVisible(true);
        }
    };

    return (
        <>
            <Header />
            <div className="wrapper">
                <div className={`sidebar ${sidebarVisible ? 'visible' : ''}`}>
                    <h2>{topic}</h2>
                    <div>
                        {subtopics.map(subtopicName => (
                            <div key={subtopicName}>
                                <hr />
                                <button 
                                    onClick={() => handleSubtopicClick(subtopicName)} 
                                    className={selectedSubtopic === subtopicName ? 'subtopic-selected' : ''}
                                >
                                    {subtopicName}
                                </button>
                            </div>
                        ))}
                    </div>
                    <Footer />
                </div>
                <div className='content'>
                    {mainContent}
                </div>
                <div className={`mobile-pullup-nav ${!sidebarVisible ? 'visible' : ''}`}>
                    <button 
                        onClick={() => navigateSubtopics('left')} 
                        disabled={!selectedSubtopic || subtopics.indexOf(selectedSubtopic) === 0}
                    >
                        Prev
                    </button>
                    <p id='study-topic' onClick={handleStudyTopicClick}>Studying: <br></br> {topic}</p>
                    <button 
                        onClick={() => navigateSubtopics('right')} 
                        disabled={!selectedSubtopic || subtopics.indexOf(selectedSubtopic) === subtopics.length - 1}
                    >
                        Next
                    </button>
                </div>
            </div>
        </>
    );
};

export default Learn;
