import React, { useState, useEffect } from 'react';
import '../styles/DataStructs.css';

const getRandomQueue = () => {
  const length = Math.floor(Math.random() * 10) + 1;
  const array = Array.from({ length }, () => Math.floor(Math.random() * 100));
  return array;
};

const Queue = () => {
  const [queue, setQueue] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    setQueue(getRandomQueue());
  }, []);

  const handleEnqueue = () => {
    setQueue([...queue, inputValue]);
    setInputValue('');
  };

  const handleDequeue = () => {
    if (queue.length > 0) {
      setQueue(queue.slice(1));
    }
  };

  const handleItemClick = (index) => {
    setSelectedIndex(index === selectedIndex ? null : index);
  };

  return (
    <div>
      <h1>Queue Visualizer</h1>
      <div className='controls-container'>
        <div className="controls">
          <div>
            <input
              type="number"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="value"
            />
            <button onClick={handleEnqueue}>Enqueue</button>
          </div>
        </div>
        <div class="controls"><button onClick={handleDequeue}>Dequeue</button></div>
      </div>
      <div className="data-struct-visualization">
        {queue.map((item, index) => (
          <div key={index} className="linkedlist-node">
            <div 
                className={`array-item ${index === selectedIndex ? 'selected' : ''}`}
                onClick={() => handleItemClick(index)}>
                {item ? item : '-'}
                <div className="index-label">{index}</div>
            </div>
            {index < queue.length - 1 && <div className="node-pointer"></div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Queue;
