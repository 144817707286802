import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const PlaneEquations = () => {
    return (
        <div>
            <h1>Equations of Planes in Space</h1>
            <div className='group'>
                <h3>General Form of a Plane Equation</h3>
                <p>A plane in 3D space can be described by the general equation:</p>
                <p><Latex>{`$$ax + by + cz = d$$`}</Latex></p>
                <p>Where <Latex>{`$a$`}</Latex>, <Latex>{`$b$`}</Latex>, and <Latex>{`$c$`}</Latex> are the coefficients that determine the orientation of the plane, and <Latex>{`$d$`}</Latex> is the distance from the origin to the plane along the normal vector.</p>
                <p><i>Example: The equation <Latex>{`$2x - 3y + 4z = 5$`}</Latex> represents a plane in 3D space.</i></p>
            </div>
            <div className='group'>
                <h3>Point-Normal Form of a Plane</h3>
                <p>The point-normal form of a plane equation uses a point <Latex>{`$P_0(x_0, y_0, z_0)$`}</Latex> on the plane and a normal vector <Latex>{`$\\mathbf{n} = \\langle a, b, c \\rangle$`}</Latex> perpendicular to the plane:</p>
                <p><Latex>{`$$(x - x_0)a + (y - y_0)b + (z - z_0)c = 0$$`}</Latex></p>
                <p>This form emphasizes that the plane is determined by its normal vector and a point on the plane.</p>
                <p><i>Example: A plane passing through the point <Latex>{`$(1, 2, 3)$`}</Latex> with a normal vector <Latex>{`$\\langle 2, -1, 4 \\rangle$`}</Latex> has the equation <Latex>{`$2(x - 1) - (y - 2) + 4(z - 3) = 0$`}</Latex>.</i></p>
            </div>
            <div className='group'>
                <h3>Vector Equation of a Plane</h3>
                <p>The vector equation of a plane can be expressed using a point on the plane and two direction vectors that lie on the plane. If <Latex>{`$\\mathbf{r}_0$`}</Latex> is the position vector of a point <Latex>{`$P_0$`}</Latex> on the plane, and <Latex>{`$\\mathbf{v}_1$`}</Latex> and <Latex>{`$\\mathbf{v}_2$`}</Latex> are direction vectors, then the vector equation is:</p>
                <p><Latex>{`$$\\mathbf{r}(s, t) = \\mathbf{r}_0 + s\\mathbf{v}_1 + t\\mathbf{v}_2$$`}</Latex></p>
                <p>Here, <Latex>{`$s$`}</Latex> and <Latex>{`$t$`}</Latex> are parameters.</p>
                <p><i>Example: If <Latex>{`$\\mathbf{r}_0 = \\langle 1, 2, 3 \\rangle$`}</Latex>, <Latex>{`$\\mathbf{v}_1 = \\langle 1, 0, 2 \\rangle$`}</Latex>, and <Latex>{`$\\mathbf{v}_2 = \\langle 0, 1, -1 \\rangle$`}</Latex>, the vector equation of the plane is <Latex>{`$$\\mathbf{r}(s, t) = \\langle 1, 2, 3 \\rangle + s\\langle 1, 0, 2 \\rangle + t\\langle 0, 1, -1 \\rangle$$`}</Latex></i></p>
            </div>
            <div className='group'>
                <h3>Plane Intersections</h3>
                <p>The intersection of two planes in space is typically a line. To find this line, solve the system of equations given by the two plane equations simultaneously.</p>
                <p><i>Example: The planes <Latex>{`$x + y + z = 6$`}</Latex> and <Latex>{`$2x - y + 3z = 10$`}</Latex> intersect along a line. By solving these equations, the parametric equations for the line of intersection can be found.</i></p>
            </div>
            <div className='group'>
                <h3>Angle Between Two Planes</h3>
                <p>The angle <Latex>{`$\\theta$`}</Latex> between two planes is determined by the angle between their normal vectors <Latex>{`$\\mathbf{n}_1$`}</Latex> and <Latex>{`$\\mathbf{n}_2$`}</Latex>. The cosine of the angle is given by:</p>
                <p><Latex>{`$$\\cos \\theta = \\frac{\\mathbf{n}_1 \\cdot \\mathbf{n}_2}{|\\mathbf{n}_1||\\mathbf{n}_2|}$$`}</Latex></p>
                <p><i>Example: For planes with normal vectors <Latex>{`$\\mathbf{n}_1 = \\langle 1, 2, 3 \\rangle$`}</Latex> and <Latex>{`$\\mathbf{n}_2 = \\langle 4, -1, 2 \\rangle$`}</Latex>, the angle between the planes can be calculated using the dot product.</i></p>
            </div>
            <button>Practice</button>
        </div>
    );
};

export default PlaneEquations;
