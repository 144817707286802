import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const Vectors2D = () => {
    return (
        <div>
            <h1>Vectors in 2 Dimensions</h1>
            <div className='group'>
                <h3>2D Vector</h3>
                <p>A <span className="emph">2D vector</span> is a quantity that has both magnitude and direction in two-dimensional space. It is represented as an ordered pair of numbers <Latex>{`$(x, y)$`}</Latex>, where <Latex>{`$x$`}</Latex> and <Latex>{`$y$`}</Latex> are the components along the x-axis and y-axis, respectively.</p>
                <p><i>Example: A vector <Latex>{`$(3, 4)$`}</Latex> represents a point 3 units along the x-axis and 4 units along the y-axis.</i></p>
            </div>
            <div className='group'>                
                <h3>Magnitude of a 2D Vector</h3>
                <p>The <span className="emph">magnitude</span> of a 2D vector <Latex>{`$\\mathbf{v} = \\langle x, y \\rangle$`}</Latex> is given by the formula:</p>
                <p><Latex>{`$$|\\mathbf{v}| = \\sqrt{x^2 + y^2}$$`}</Latex></p>
                <p><i>Example: The magnitude of the vector <Latex>{`$(3, 4)$`}</Latex> is 5.</i></p>
            </div>
            <div className='group'>
                <h3>Vector Addition in 2D</h3>
                <p><span className="emph">Vector addition</span> in 2D combines two vectors to produce a third vector. If <Latex>{`$\\mathbf{v} = \\langle x_1, y_1 \\rangle$`}</Latex> and <Latex>{`$\\mathbf{w} = \\langle x_2, y_2 \\rangle$`}</Latex>, then:</p>
                <p><Latex>{`$$\\mathbf{v} + \\mathbf{w} = \\langle x_1 + x_2, y_1 + y_2 \\rangle$$`}</Latex></p>
                <p><i>Example: Adding vectors <Latex>{`$(2, 3)$`}</Latex> and <Latex>{`$(1, 4)$`}</Latex> results in the vector <Latex>{`$(3, 7)$`}</Latex>.</i></p>
            </div>
            <div className='group'>
                <h3>Dot Product in 2D</h3>
                <p>The <span className="emph">dot product</span> of two 2D vectors <Latex>{`$\\mathbf{v} = \\langle x_1, y_1 \\rangle$`}</Latex> and <Latex>{`$\\mathbf{w} = \\langle x_2, y_2 \\rangle$`}</Latex> is given by:</p>
                <p><Latex>{`$$\\mathbf{v} \\cdot \\mathbf{w} = x_1x_2 + y_1y_2$$`}</Latex></p>
                <p><i>Example: The dot product of vectors <Latex>{`$(2, 3)$`}</Latex> and <Latex>{`$(1, 4)$`}</Latex> is 14.</i></p>
            </div>
            <div className='group'>
                <h3>Unit Vectors in 2D</h3>
                <p>A <span className="emph">unit vector</span> in 2D has a magnitude of 1 and points in a specific direction. To normalize a vector <Latex>{`$\\mathbf{v} = \\langle x, y \\rangle$`}</Latex>, divide it by its magnitude:</p>
                <p><Latex>{`$$\\mathbf{u} = \\frac{\\mathbf{v}}{|\\mathbf{v}|} = \\left\\langle \\frac{x}{|\\mathbf{v}|}, \\frac{y}{|\\mathbf{v}|} \\right\\rangle$$`}</Latex></p>
                <p><i>Example: The unit vector in the direction of <Latex>{`$(3, 4)$`}</Latex> is <Latex>{`$\\left( \\frac{3}{5}, \\frac{4}{5} \\right)$`}</Latex>.</i></p>
            </div>
        </div>
    );
};

export default Vectors2D;
