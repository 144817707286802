import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const OntoOneToOne = () => {
    return (
        <div>
            <h1>Functions and Their Properties</h1>
            <div className='group'>
                <h3>Functions</h3>
                <p>
                    A <span className='emph'>function</span> from a set <Latex>$A$</Latex> to a set <Latex>$B$</Latex> is a rule that assigns each element of <Latex>$A$</Latex> to exactly one element of <Latex>$B$</Latex>. This is often written as <Latex>$f: A \rightarrow B$</Latex>.
                </p>
                <p><i>Example: Let <Latex>{`$A = \\{1, 2, 3\\}$`}</Latex> and <Latex>{`$B = \\{x, y, z\\}`}$</Latex>. A function <Latex>$f$</Latex> could be defined as <Latex>$f(1) = x$</Latex>, <Latex>$f(2) = y$</Latex>, and <Latex>$f(3) = z$</Latex>.</i></p>
            </div>
            <div className='group'>
                <h3>One-to-One (Injective) Functions</h3>
                <p>
                    A function <Latex>$f: A \rightarrow B$</Latex> is called <span className='emph'>one-to-one</span> or <span className='emph'>injective</span> if no two elements in <Latex>$A$</Latex> map to the same element in <Latex>$B$</Latex>. Formally, <Latex>{`$f(a_1) = f(a_2)$`}</Latex> implies <Latex>{`$a_1 = a_2$`}</Latex>.
                </p>
                <p><i>Example: If <Latex>$f(1) = x$</Latex>, <Latex>$f(2) = y$</Latex>, and <Latex>$f(3) = z$</Latex>, then <Latex>$f$</Latex> is one-to-one.</i></p>
                <p><i>If <Latex>$f(1) = x$</Latex> and <Latex>$f(2) = x$</Latex>, then <Latex>$f$</Latex> is <b>not</b> one-to-one.</i></p>
            </div>
            <div className='group'>
                <h3>Onto (Surjective) Functions</h3>
                <p>
                    A function <Latex>$f: A \rightarrow B$</Latex> is called <span className='emph'>onto</span> or <span className='emph'>surjective</span> if every element of <Latex>$B$</Latex> is mapped to by at least one element of <Latex>$A$</Latex>. Formally, for every <Latex>$b \in B$</Latex>, there exists at least one <Latex>$a \in A$</Latex> such that <Latex>$f(a) = b$</Latex>.
                </p>
                <p><i>Example: If <Latex>$f(1) = x$</Latex>, <Latex>$f(2) = y$</Latex>, and <Latex>$f(3) = z$</Latex>, then <Latex>$f$</Latex> is onto.</i></p>
                <p><i>If <Latex>{`$B = \{x, y, z\}$`}</Latex> but <Latex>$f(1) = x$</Latex> and <Latex>$f(2) = y$</Latex>, leaving <Latex>$z$</Latex> unmapped, then <Latex>$f$</Latex> is <b>not</b> onto.</i></p>
            </div>
            <div className='group'>
                <h3>Bijective Functions</h3>
                <p>
                    A function <Latex>$f: A \rightarrow B$</Latex> is called <span className='emph'>bijective</span> if it is both one-to-one and onto. This means that <Latex>$f$</Latex> is a perfect pairing between the elements of <Latex>$A$</Latex> and <Latex>$B$</Latex>, with each element of <Latex>$A$</Latex> mapping to a unique element of <Latex>$B$</Latex> and vice versa.
                </p>
                <p><i>Example: If <Latex>$f(1) = x$</Latex>, <Latex>$f(2) = y$</Latex>, and <Latex>$f(3) = z$</Latex>, and <Latex>{`$B = \\{x, y, z\\}$`}</Latex>, then <Latex>$f$</Latex> is bijective.</i></p>
            </div>
            <div className='group'>
                <h3>Pigeonhole Principle</h3>
                <p>
                    The <span className='emph'>Pigeonhole Principle</span> states that if you have more "pigeons" than "pigeonholes" and you want to place each pigeon in a pigeonhole, then at least one pigeonhole must contain more than one pigeon.
                </p>
                <p>
                    Formally, if <Latex>$n$</Latex> items are put into <Latex>$m$</Latex> containers, and <Latex>{`$n > m$`}</Latex>, then at least one container must contain more than one item.
                </p>
                <p><i>Example: If you have 4 shirts and 3 drawers, and all shirts are placed into a drawer, then at least one drawer must contain more than one shirt.</i></p>
            </div>

        </div>
    );
};

export default OntoOneToOne;
