import React from 'react';
import PascalsTriangle from '../components/PascalTriangle';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const Combinations = () => {
    return (
        <div>
            <h1>Combinations</h1>

            <div className='group'>
                <h3>Combinations</h3>
                <p>
                    A <span className='emph'>combination</span> is a selection of items from a larger set where the order of selection does not matter. The number of combinations of <Latex>$r$</Latex> objects you can make from a set of <Latex>$n$</Latex> objects is denoted as <Latex>$_nC_r$</Latex> ("<Latex>$n$</Latex> choose <Latex>$r$</Latex>") and is calculated using:
                </p>
                <Latex>{'$_nC_r = \\frac{n!}{r!(n-r)!}$'}</Latex>
                <p>
                    <i>Example: The number of ways to choose 2 objects out of 4 (A, B, C, D) is <Latex>{`$_4C_2 = \\frac{4!}{2!(4-2)!} = 6$`}</Latex>.</i>
                </p>
            </div>            

            <div className='group'>
                <h3>Combinations with Repetition</h3>
                <p>
                    When repetition is allowed, the number of combinations of <Latex>$r$</Latex> objects you can make from a set of <Latex>$n$</Latex> objects is given by:
                </p>
                <Latex>{'$_nC_r = \\frac{(n+r-1)!}{r!(n-1)!}$'}</Latex>
                <p>
                    <i>Example: The number of ways to choose 3 objects out of 4 (A, B, C, D) with repetition is <Latex>{`$_4C_3 = \\frac{(4+3-1)!}{3!(4-1)!} = 20$`}</Latex>.</i>
                </p>
            </div>            

            {/* <div className='group' style={{ width: 'fit-content'}}>
                <h3>Pascal's Triangle</h3>
                <p>
                    Pascal's Triangle is a useful tool for visualizing combinations. The <Latex>{`$n^{th}$`}</Latex> row of Pascal's Triangle gives the coefficients of the binomial expansion and corresponds to the values of <Latex>{`$_nC_r$`}</Latex>.
                </p>
                <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex' }}>
                    <PascalsTriangle />
                </div>
            </div>             */}

            <div className='group'>
                <h3>Binomial Theorem</h3>
                <p>
                    The <span class="emph">binomial theorem</span> provides a way to expand expressions of the form <Latex>$(a + b)^n$</Latex>. It states that:
                </p>
                <Latex>{'$(a + b)^n = \\sum_{r=0}^{n} \\binom{n}{r} a^{n-r} b^r$'}</Latex>
                <p>
                    Here, <Latex>{`$\\binom{n}{r}$`}</Latex> represents the number of combinations, which are also the coefficients in the expansion.
                </p>
                <p>
                    <i>Example: Expanding <Latex>{`$(a + b)^2$`}</Latex> gives:
                    <Latex>{'$(a + b)^2 = \\binom{2}{0} a^2 + \\binom{2}{1} a^1b^1 + \\binom{2}{2} b^2 = a^2 + 2ab + b^2$'}</Latex>
                    {/* Note that the coefficients are just the <Latex>{`$n^{th}$`}</Latex> row of Pascal's triangle, in this case the 2nd row. */}
                    </i>
                </p>
            </div>
            
        </div>
    );
};

export default Combinations;
