import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const FundamentalTheoremOfArithmetic = () => {
    return (
        <div>
            <h1>Fundamental Theorem of Arithmetic</h1>
            <div className='group'>
                <h3>Prime Numbers</h3>
                <p>
                    A <span className='emph'>prime number</span> is a natural number greater than 1 that has no positive divisors other than 1 and itself.
                </p>
                <p><i>Example: The first few prime numbers are 2, 3, 5, 7, 11, 13, 17, 19, and so on.</i></p>
            </div>
            <div className='group'>
                <h3>Fundamental Theorem of Arithmetic</h3>
                <p>
                    The <span className='emph'>Fundamental Theorem of Arithmetic</span> states that every integer greater than 1 can be uniquely factored into prime numbers, up to the order of the factors.
                </p>
                <p><i>Example: The number 60 can be factored into prime numbers as <Latex>$60 = 2^2 \times 3 \times 5$</Latex>. No other combination of primes will give 60.</i></p>
            </div>
            <div className='group'>
                <h3>Proof Example</h3>
                <p>
                    Let's prove a simple case of the Fundamental Theorem of Arithmetic for the number 30:
                </p>
                <p className='center'>
                    <Latex>{`$30 = 2 \\times 3 \\times 5$`}</Latex>
                </p>
                <p>
                    Assume there is another factorization of 30, say <Latex>{`$30 = p_1 \\times p_2 \\times \\dots \\times p_k$`}</Latex> where each <Latex>{`$p_i$`}</Latex> is prime. Since 30 is composed of the primes 2, 3, and 5, and prime factorizations are unique, <Latex>{`$p_1, p_2, \\dots, p_k$`}</Latex> must be some rearrangement of 2, 3, and 5.
                </p>
            </div>
        </div>
    );
};

export default FundamentalTheoremOfArithmetic;
