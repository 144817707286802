import React, { useState, useEffect } from 'react';
import '../styles/DataStructs.css';

const getRandomArray = () => {
  const length = Math.floor(Math.random() * 10) + 1;
  const array = Array.from({ length }, () => Math.floor(Math.random() * 100));
  return array;
};

const BubbleSort = () => {
  const [array, setArray] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [sorted, setSorted] = useState(false);
  const [iterations, setIterations] = useState([]);

  useEffect(() => {
    const initialArray = getRandomArray();
    setArray(initialArray);
    setIterations([initialArray]);
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputArray = () => {
    const newArray = inputValue.split(',').map(Number);
    setArray(newArray);
    setSorted(false);
    setIterations([newArray]);
  };

  const bubbleSort = (arr) => {
    let sortedArray = [...arr];
    let n = sortedArray.length;
    let newIterations = [];
    for (let i = 0; i < n - 1; i++) {
      for (let j = 0; j < n - i - 1; j++) {
        if (sortedArray[j] > sortedArray[j + 1]) {
          [sortedArray[j], sortedArray[j + 1]] = [sortedArray[j + 1], sortedArray[j]];
        }
        newIterations.push([...sortedArray]);
      }
    }
    return newIterations;
  };

  const handleSort = () => {
    const sortIterations = bubbleSort(array);
    setIterations(sortIterations);
    setSorted(true);
  };

  return (
    <div>
      <h1>Bubble Sort Visualizer</h1>
      <div className='controls-container'>
        <div className="controls">
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Enter comma separated values"
          />
          <button onClick={handleInputArray}>Set Array</button>
          <button onClick={handleSort}>Sort</button>
        </div>
      </div>
      <div className="algorithm-visualization">
        {iterations.map((iteration, iterIndex) => (
          <div key={iterIndex} className="iteration">
            {iteration.map((item, index) => (
              <div key={index} className="linkedlist-node">
                <div className="array-item">
                  {item ? item : '-'}
                  <div className="index-label">{index}</div>
                </div>
                {index < iteration.length - 1 && <div className="node-pointer"></div>}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BubbleSort;
