import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const DivisionAlgorithm = () => {
    return (
        <div>
            <h1>Division Algorithm</h1>

            <div className='group'>
                <h3>Division Algorithm</h3>
                <p>
                    The <span className='emph'>Division Algorithm</span> states that for any integers <Latex>$a$</Latex> and <Latex>$b$</Latex> (with <Latex>$b \neq 0$</Latex>), there exist unique integers <Latex>$q$</Latex> (the quotient) and <Latex>$r$</Latex> (the remainder) such that:
                </p>
                <p style={{ textAlign: 'center' }}>
                    <Latex>{`a = bq + r`}</Latex>
                </p>
                <p>
                    where <Latex>{`$0 \\leq r < |b|$`}</Latex>.
                </p>
            </div>

            <div className='group'>
                <h3>Understanding the Formula</h3>
                <p>
                    In the formula <Latex>$a = bq + r$</Latex>:
                </p>
                <ul>
                    <li>
                        <Latex>$a$</Latex> is the dividend (the number you want to divide).
                    </li>
                    <li>
                        <Latex>$b$</Latex> is the divisor (the number you are dividing by).
                    </li>
                    <li>
                        <Latex>$q$</Latex> is the quotient (the result of the division, without considering the remainder).
                    </li>
                    <li>
                        <Latex>$r$</Latex> is the remainder (what is left over after the division).
                    </li>
                </ul>
                <p>
                    The Division Algorithm guarantees that the quotient <Latex>$q$</Latex> and remainder <Latex>$r$</Latex> are unique for any given <Latex>$a$</Latex> and <Latex>$b$</Latex>.
                </p>
            </div>

            <div className='group'>
                <h3>Examples</h3>
                <p>
                    Let's look at some examples to understand how the Division Algorithm works in practice:
                </p>

                <h4>Example 1</h4>
                <p>
                    Divide 17 by 5. Here, <Latex>$a = 17$</Latex> and <Latex>$b = 5$</Latex>.
                </p>
                <ul>
                    <li>
                        <b>Step 1:</b> Calculate the quotient: <Latex>{`$q = \\text{floor}(\\frac{17}{5}) = 3$`}</Latex>.
                    </li>
                    <li>
                        <b>Step 2:</b> Calculate the remainder: <Latex>$r = 17 - 5 \times 3 = 2$</Latex>.
                    </li>
                </ul>
                <p>
                    Therefore, <Latex>$17 = 5 \times 3 + 2$</Latex> where <Latex>$q = 3$</Latex> and <Latex>$r = 2$</Latex>.
                </p>

                <h4>Example 2</h4>
                <p>
                    Divide -11 by 3. Here, <Latex>$a = -11$</Latex> and <Latex>$b = 3$</Latex>.
                </p>
                <ul>
                    <li>
                        <b>Step 1:</b> Calculate the quotient: <Latex>{`$q = \\text{floor}(\\frac{-11}{3}) = -4$`}</Latex>.
                    </li>
                    <li>
                        <b>Step 2:</b> Calculate the remainder: <Latex>$r = -11 - 3 \times (-4) = 1$</Latex>.
                    </li>
                </ul>
                <p>
                    Therefore, <Latex>$-11 = 3 \times (-4) + 1$</Latex> where <Latex>$q = -4$</Latex> and <Latex>$r = 1$</Latex>.
                </p>
            </div>
        </div>
    );
};

export default DivisionAlgorithm;
