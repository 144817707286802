import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const InverseFunctions = () => {
    return (
        <div>
            <h1>Inverse Functions</h1>
            <div className='group'>
                <h3>Inverse Functions</h3>
                <p>
                    If <Latex>$f: A \rightarrow B$</Latex> is a bijective function (both one-to-one and onto), then there exists an <span className='emph'>inverse function</span> <Latex>$f^{-1}: B \rightarrow A$</Latex> such that for every <Latex>$x \in A$</Latex> and <Latex>$y \in B$</Latex>:
                </p>
                <p className='center'>
                    <Latex>{`$f^{-1}(f(x)) = x$`}</Latex> and <Latex>{`$f(f^{-1}(y)) = y$`}</Latex>.
                </p>
                <p>
                    The inverse function <Latex>$f^{-1}$</Latex> essentially "reverses" the effect of the original function <Latex>$f$</Latex>.
                </p>
            </div>
            <div className='group'>
                <h3>Properties of Inverse Functions</h3>
                <p>
                    The inverse function has the following key properties:
                </p>
                <ul>
                    <li><Latex>{`$f^{-1}\\circ f = id_A$`}</Latex>: Composing the inverse function with the original function yields the identity function on set <Latex>$A$</Latex>.</li>
                    <li><Latex>{`$f\\circ f^{-1} = id_B$`}</Latex>: Composing the original function with the inverse function yields the identity function on set <Latex>$B$</Latex>.</li>
                    <li>Not every function has an inverse. Only bijective functions (one-to-one and onto) have inverses.</li>
                </ul>
            </div>
            <div className='group'>
                <h3>Example</h3>
                <p>
                    Suppose <Latex>$f(x) = 2x + 3$</Latex>. To find the inverse, solve for <Latex>$x$</Latex> in terms of <Latex>$y$</Latex>:
                </p>
                <p className='center'>
                    <Latex>{`$y = 2x + 3$`}</Latex>
                    <br />
                    <Latex>{`$x = \\frac{y - 3}{2}$`}</Latex>
                </p>
                <p>
                    Thus, the inverse function is <Latex>{`$f^{-1}(y) = \\frac{y - 3}{2}$`}</Latex>. Verifying the inverse:
                </p>
                <p className='center'>
                    <Latex>{`$f^{-1}(f(x)) = \\frac{(2x + 3) - 3}{2} = x$`}</Latex>
                    <br />
                    <Latex>{`$f(f^{-1}(y)) = 2\\left(\\frac{y - 3}{2}\\right) + 3 = y$`}</Latex>
                </p>
                <p>
                    Both conditions for the inverse function are satisfied.
                </p>
            </div>
        </div>
    );
};

export default InverseFunctions;
