import React, { useState, useEffect } from 'react';

const RandomExperiments = () => {
    return (
        <div>
            <h1>Random Experiments</h1>
            <div className='group'>
                <h3>Random Experiment</h3>
                <p>A <span class="emph">random experiment</span> is always performed the same way, but can result in different outcomes.</p>
                <p><i>Example: Flipping a coin</i></p>
            </div>
            <div className='group'>                
                <h3>Sample Space</h3>
                    <p>The set of all possible outcomes in a random experiment is called the <span class="emph">sample space</span>, <i>S</i>.</p>
                    
                <p><i>Example: The result of a coin flip - heads or tails</i></p>
            </div>
            <div className='group'>
                <h3>Discrete Sample Space</h3>
                    <p>A <span className='emph'>discrete sample space</span> has:</p>
                        <ul>
                            <li>
                                <p>A finite set of outcomes.</p>
                                <p><i>Example: The result of a coin flip - heads or tails</i></p>
                            </li>                            
                                <p>OR</p>
                            <li>
                                <p>A countable infinite set of outcomes.</p>
                                <p><i>Example: The number of times it takes to flip a coin until it lands on heads</i></p>
                            </li>
                        </ul>
            </div>
            <div className='group'>
                <h3>Continuous Sample Space</h3>
                <p>A <span class="emph">continuous sample space</span> contains an interval of real numbers.</p>
                <p><i>Example: Measuring the height of a randomly selected person from a population</i></p>
            </div>
        </div>
    );
};

export default RandomExperiments;