import React from 'react';
import { Canvas, extend } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

// CylindricalCoordinates component definition
const CylindricalCoordinates = () => {
    return (
        <div>
            <h1>Cylindrical Coordinates</h1>
            <div className='group'>
                <h3>Cylindrical Coordinates</h3>
                <p><span class="emph">Cylindrical coordinates</span> <Latex>{`$(r, \\theta, z)$`}</Latex> are a system of coordinates that extends polar coordinates into three dimensions by using a third coordinate, the height <Latex>{`$z$`}</Latex>. The coordinates are defined as follows:</p>
                <ul>
                    <li><Latex>{`$r$`}</Latex>: The radial distance from the z-axis (similar to the radius in polar coordinates).</li>
                    <li><Latex>{`$\\theta$`}</Latex>: The angle in the xy-plane from the positive x-axis.</li>
                    <li><Latex>{`$z$`}</Latex>: The height above or below the xy-plane.</li>
                </ul>
            </div>
            <div className='group'>
                <h3>Interactive Visualization</h3>
                <p>Below is an interactive visualization of cylindrical coordinates. You can rotate and zoom to explore the 3D representation.</p>
                <CylindricalVisualization />
            </div>
            <div className='group'>
                <h3>Conversion Formulas</h3>
                <p>The conversion between rectangular coordinates <Latex>{`$(x, y, z)$`}</Latex> and cylindrical coordinates <Latex>{`$(r, \\theta, z)$`}</Latex> is given by the following formulas:</p>
                <h4>From Rectangular to Cylindrical:</h4>
                <ul>
                    <li><Latex>{`$r = \\sqrt{x^2 + y^2}$`}</Latex></li>
                    <li><Latex>{`$\\theta = \\tan^{-1}\\left(\\frac{y}{x}\\right)$`}</Latex></li>
                    <li><Latex>{`$z = z$`}</Latex></li>
                </ul>
                <h4>From Cylindrical to Rectangular:</h4>
                <ul>
                    <li><Latex>{`$x = r \\cos \\theta$`}</Latex></li>
                    <li><Latex>{`$y = r \\sin \\theta$`}</Latex></li>
                    <li><Latex>{`$z = z$`}</Latex></li>
                </ul>
            </div>
        </div>
    );
};

// CylindricalVisualization component for the interactive 3D visualization
const CylindricalVisualization = () => {
    return (
        <Canvas style={{ height: '500px', border: '2px solid black', borderRadius: '8px' }}>
            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 10]} />
            <axesHelper args={[5]} /> {/* Adds coordinate axes */}
            <gridHelper args={[10, 10]} /> {/* Adds a grid */}
            <CylindricalSurface />
            <OrbitControls />
        </Canvas>
    );
};

// CylindricalSurface component to visualize the surface in cylindrical coordinates
const CylindricalSurface = () => {
    const cylinderRef = React.useRef();

    return (
        <mesh ref={cylinderRef} rotation={[Math.PI / 2, 0, 0]}>
            <cylinderGeometry args={[2, 2, 5, 32]} /> {/* A simple cylinder representing r=2 and height=5 */}
            <meshStandardMaterial color="orange" wireframe={true} />
        </mesh>
    );
};

export default CylindricalCoordinates;
