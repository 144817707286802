import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const Derangements = () => {
    return (
        <div>
            <h1>Derangements</h1>
            <div className='group'>
                <h3>Derangement</h3>
                <p>
                    A <span className='emph'>derangement</span> is a permutation of a set where none of the elements appear in their original positions.
                </p>
                <p><i>Example: For the set <Latex>{`$\\{1, 2, 3\\}$`}</Latex>, the permutations <Latex>$(2, 3, 1)$</Latex> and <Latex>$(3, 1, 2)$</Latex> are derangements, while <Latex>$(1, 3, 2)$</Latex> is not, because the first element remains in its original position.</i></p>
            </div>
            <div className='group'>
                <h3>Counting Derangements</h3>
                <p>
                    The number of derangements of a set of <Latex>$n$</Latex> elements, denoted by <Latex>$!n$</Latex> (read as "<Latex>$n$</Latex> subfactorial"), can be computed using the formula: <Latex>{`$$!n = n! \\sum_{k=0}^{n} \\frac{(-1)^k}{k!}$$`}</Latex>
                </p>
                <p>
                    This formula uses the principle of inclusion-exclusion to subtract out the arrangements where one or more elements remain in their original positions.
                </p>
                <p><i>Example: For <Latex>$n = 3$</Latex>, the number of derangements is calculated as follows:</i></p>
                <p className='center'>
                    <Latex>{`$!3 = 3! \\left(\\frac{(-1)^0}{0!} + \\frac{(-1)^1}{1!} + \\frac{(-1)^2}{2!} + \\frac{(-1)^3}{3!}\\right) = 6 \\left(1 - 1 + \\frac{1}{2} - \\frac{1}{6}\\right) = 2$`}</Latex>
                </p>
            </div>
            <div className='group'>
                <h3>Recursive Formula for Derangements</h3>
                <p>
                    Derangements can also be computed using a recursive formula: 
                    <Latex>{`$$!n = (n - 1) \\times (!(n - 1) + !(n - 2))$$`}</Latex>
                </p>
                <p>
                    This formula expresses the number of derangements of <Latex>$n$</Latex> elements in terms of the derangements of <Latex>$n-1$</Latex> and <Latex>$n-2$</Latex> elements.
                </p>
                <p><i>Example: To compute <Latex>$!4$</Latex> using the recursive formula, start with known values <Latex>$!2 = 1$</Latex> and <Latex>$!3 = 2$</Latex>:</i></p>
                <p className='center'>
                    <Latex>{`$!4 = (4 - 1) \\times (!(3) + !(2)) = 3 \\times (2 + 1) = 9$`}</Latex>
                </p>
            </div>
        </div>
    );
};

export default Derangements;
