import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const Graphs = () => {
    return (
        <div>
            <h1>Graphs</h1>
            
            <div className='group'>
                <h3>Graphs</h3>
                <p>
                    A <span className='emph'>graph</span> is a mathematical structure used to model pairwise relationships between objects. It consists of two primary components:
                </p>
                <ul>
                    <li><b>Vertices</b> (or nodes): The points in the graph, representing the objects.</li>
                    <li><b>Edges</b> (or links): The connections between the vertices, representing the relationships between the objects.</li>
                </ul>
                <p>
                    Graphs can be visualized as diagrams with points (vertices) connected by lines (edges).
                </p>
            </div>

            <div className='group'>
                <h3>Directed and Undirected Graphs</h3>
                <p>
                    Graphs can be classified based on the directionality of their edges:
                </p>
                <ul>
                    <li><b>Undirected Graph:</b> In an undirected graph, edges have no direction. The edge between vertices <Latex>{`$u$`}</Latex> and <Latex>{`$v$`}</Latex> is denoted as <Latex>{`$\\{u, v\\}$`}</Latex> and can be traversed in both directions.</li>
                    <li><b>Directed Graph (Digraph):</b> In a directed graph, edges have a direction. The edge from vertex <Latex>{`$u$`}</Latex> to vertex <Latex>{`$v$`}</Latex> is denoted as <Latex>{`$(u, v)$`}</Latex> and can only be traversed from <Latex>{`$u$`}</Latex> to <Latex>{`$v$`}</Latex>.</li>
                </ul>
            </div>

            <div className='group'>
                <h3>Walks, Trails, and Paths</h3>
                <p>
                    A <span className='emph'>walk</span> in a graph is a sequence of vertices where each adjacent pair of vertices is connected by an edge. The concepts of trails and paths refine this idea:
                </p>
                <ul>
                    <li><b>Walk:</b> A sequence of vertices and edges, where vertices and edges can be repeated.</li>
                    <li><b>Trail:</b> A walk in which no edge is repeated, though vertices may be repeated.</li>
                    <li><b>Path:</b> A trail in which no vertex is repeated, meaning it is both edge-simple and vertex-simple.</li>
                </ul>
            </div>

            <div className='group'>
                <h3>Closed Walks, Circuits, and Cycles</h3>
                <p>
                    A <span className='emph'>closed walk</span> is a walk that starts and ends at the same vertex.
                </p>
                <p>A <span className='emph'>circuit</span> is a closed trail (no repeated edges) where the start and end vertices are the same.</p>
                <p>A <span className='emph'>cycle</span> is a closed path (no repeated vertices, except the start and end) where the start and end vertices are the same.</p>
            </div>

            <div className='group'>
                <h3>Loop-Free and Multigraphs</h3>
                <p>
                    A graph may or may not have loops and multiple edges:
                </p>
                <ul>
                    <li><b>Loop-Free Graph:</b> A graph with no loops, where a loop is an edge that connects a vertex to itself.</li>
                    <li><b>Multigraph:</b> A graph that can have multiple edges (parallel edges) between the same pair of vertices.</li>
                </ul>
            </div>

            <div className='group'>
                <h3>Connected and Disconnected Graphs</h3>
                <p>
                    A graph is <span className='emph'>connected</span> if there is a path between any two vertices. If the graph is not connected, it is called <span className='emph'>disconnected</span>.
                </p>
            </div>

            <div className='group'>
                <h3>Distance in a Graph</h3>
                <p>
                    The <span className='emph'>distance</span> between two vertices in a graph is the length of the shortest path connecting them. The distance provides a measure of how "far apart" two vertices are within the graph.
                </p>
                <p><i>Example: In a simple undirected graph, the distance between two vertices is the minimum number of edges required to traverse from one vertex to the other.</i></p>
            </div>

        </div>
    );
};

export default Graphs;
