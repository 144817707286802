import React, { useEffect, useRef } from 'react';
import Vex from 'vexflow';
import * as Tone from 'tone';

const VF = Vex.Flow;

const ClefsNotes = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        // Clear previous rendering
        containerRef.current.innerHTML = '';

        const vf = new VF.Factory({
            renderer: { elementId: containerRef.current, width: 700, height: 200 }, // Adjusted width for 4 bars
        });

        const score = vf.EasyScore();
        const system = vf.System({ width: 700 }); // Create a system with the full width of the container

        // Notes for the first 4 bars of "Ode to Joy"
        const notesData = [
            { pitches: 'E4/q, E4/q, F4/q, G4/q', pitchesArray: ['E4', 'E4', 'F4', 'G4'] },
            { pitches: 'G4/q, F4/q, E4/q, D4/q', pitchesArray: ['G4', 'F4', 'E4', 'D4'] },
            { pitches: 'C4/q, C4/q, D4/q, E4/q', pitchesArray: ['C4', 'C4', 'D4', 'E4'] },
            { pitches: 'E4/h, D4/h', pitchesArray: ['E4', 'D4'] }
        ];

        // Create a synth and connect it to the master output
        const synth = new Tone.Synth().toDestination();

        // Function to play the note
        const playNote = (pitch) => {
            synth.triggerAttackRelease(pitch, '8n');
        };

        // Create a stave for each bar of music
        notesData.forEach((bar, index) => {
            const notes = score.notes(bar.pitches, { stem: 'up' });
            const voice = score.voice(notes);

            const stave = system.addStave({
                voices: [voice],
                x: index * 175, // Position each stave horizontally
                y: 0, // Align all staves vertically
                width: 175 // Set the width of each bar
            });

            if (index === 0) {
                stave.addClef('treble').addTimeSignature('4/4'); // Add clef and time signature to the first stave
            }

            // Add click event to each note using the Modifier context
            notes.forEach((note, noteIndex) => {
                note.addModifier(new VF.Annotation('click').setFont('Arial', 1), 0); // Dummy annotation for click area
                note.attrs.el.addEventListener('click', () => {
                    playNote(bar.pitchesArray[noteIndex]);
                });
            });
        });

        vf.draw();

        // Cleanup on component unmount
        return () => {
            containerRef.current.innerHTML = '';
        };
    }, []); // [] ensures this effect runs only once after the initial render

    return (
        <div>
            <h1>Treble Clef Notes</h1>
            <div className='group'>
                <div ref={containerRef} className='music-staff'></div>
                <h3>Introduction to the Treble Clef</h3>
                <p>
                    The <span className='emph'>treble clef</span>, also known as the G clef, is a symbol used in music notation to indicate the pitch of written notes. It is primarily used for higher-pitched instruments and voices. The treble clef symbol circles around the G note on the second line of the staff.
                </p>
                <p>
                    The staff is made up of five horizontal lines, and notes can be placed on either the lines or the spaces between them. The pitch of the note is determined by its position on the staff.
                </p>
            </div>
            <div className='group'>
                <h3>Notes on the Treble Clef Staff</h3>
                <p>
                    The notes on the lines of the treble clef staff, from bottom to top, are E, G, B, D, and F.
                </p>
                <p>
                    The notes in the spaces between the lines, from bottom to top, spell out the word FACE.
                </p>
                <p>
                    The visualization above shows the notes on the treble clef staff, with each note labeled according to its position.
                </p>
            </div>
            <div className='group'>
                <h3>Middle C</h3>
                <p>
                    Middle C is a pivotal note in music, positioned on a ledger line below the treble clef staff. It is the C note closest to the middle of a standard piano keyboard.
                </p>
                <p>
                    In the treble clef, Middle C is written just below the first line of the staff:
                </p>
            </div>
        </div>
    );
};

export default ClefsNotes;
