import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const Permutations = () => {
    return (
        <div>
            <h1>Permutations</h1>
            <div className='group'>
                <h3>Permutations</h3>
                <p>
                    A <span className='emph'>permutation</span> is an arrangement of objects in a specific order.
                </p>
                <p><i>Example: There are 6 different ways to arrange the letters A, B, and C: ABC, ACB, BAC, BCA, CAB, and CBA. These are all distinct permutations.</i></p>
            </div>
            <div className='group'>
                <h3>Number of Permutations</h3>
                <p>
                    The number of permutations of <Latex>$n$</Latex> distinct objects is given by <Latex>$n!$</Latex> (<Latex>$n$</Latex> factorial), which is the product of all positive integers up to <Latex>$n$</Latex>.
                </p>
                <p><i>Example: For 3 objects (A, B, C), the number of permutations is <Latex>$3! = 3 \times 2 \times 1 = 6$</Latex>.</i></p>
            </div>
            <div className='group'>
                <h3>Permutations of Subsets</h3>
                <p>
                    If we have a set of <Latex>$n$</Latex> objects and we want to find the number of ways to arrange a subset of <Latex>$r$</Latex> objects, the number of permutations is given by:
                </p>
                <p className='center'>
                    <Latex>{`$P(n, r) = \\frac{n!}{(n-r)!}$`}</Latex>
                </p>
                <p><i>Example: The number of ways to arrange 2 objects out of 3 (A, B, C) is <Latex>{`$P(3, 2) = \\frac{3\!}{(3-2)\!} = \\frac{6}{1} = 6$`}</Latex>.</i></p>
            </div>
        </div>
    );
};

export default Permutations;
