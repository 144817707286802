import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const Vectors3D = () => {
    return (
        <div>
            <h1>Vectors in 3 Dimensions</h1>
            <div className='group'>
                <h3>3D Vector</h3>
                <p>A <span className="emph">3D vector</span> is a quantity that has both magnitude and direction in three-dimensional space. It is represented as an ordered triple of numbers <Latex>{`$(x, y, z)$`}</Latex>, where <Latex>{`$x$`}</Latex>, <Latex>{`$y$`}</Latex>, and <Latex>{`$z$`}</Latex> are the components along the x-axis, y-axis, and z-axis, respectively.</p>
                <p><i>Example: The position of a point in space relative to the origin can be described by a vector <Latex>{`$(3, 4, 5)$`}</Latex>.</i></p>
            </div>
            <div className='group'>                
                <h3>Magnitude of a 3D Vector</h3>
                <p>The <span className="emph">magnitude</span> of a 3D vector <Latex>{`$\\mathbf{v} = \\langle x, y, z \\rangle$`}</Latex> is given by the formula:</p>
                <p><Latex>{`$$|\\mathbf{v}| = \\sqrt{x^2 + y^2 + z^2}$$`}</Latex></p>
                <p><i>Example: The magnitude of the vector <Latex>{`$(3, 4, 5)$`}</Latex> is <Latex>{`$\\sqrt{50}$`}</Latex>, approximately 7.07.</i></p>
            </div>
            <div className='group'>
                <h3>Vector Addition in 3D</h3>
                <p><span className="emph">Vector addition</span> in 3D is similar to 2D. If <Latex>{`$\\mathbf{v} = \\langle x_1, y_1, z_1 \\rangle$`}</Latex> and <Latex>{`$\\mathbf{w} = \\langle x_2, y_2, z_2 \\rangle$`}</Latex>, then:</p>
                <p><Latex>{`$$\\mathbf{v} + \\mathbf{w} = \\langle x_1 + x_2, y_1 + y_2, z_1 + z_2 \\rangle$$`}</Latex></p>
                <p><i>Example: Adding vectors <Latex>{`$(1, 2, 3)$`}</Latex> and <Latex>{`$(4, -1, 5)$`}</Latex> results in the vector <Latex>{`$(5, 1, 8)$`}</Latex>.</i></p>
            </div>
            <div className='group'>
                <h3>Dot Product in 3D</h3>
                <p>The <span className="emph">dot product</span> of two 3D vectors <Latex>{`$\\mathbf{v} = \\langle x_1, y_1, z_1 \\rangle$`}</Latex> and <Latex>{`$\\mathbf{w} = \\langle x_2, y_2, z_2 \\rangle$`}</Latex> is given by:</p>
                <p><Latex>{`$$\\mathbf{v} \\cdot \\mathbf{w} = x_1x_2 + y_1y_2 + z_1z_2$$`}</Latex></p>
                <p><i>Example: The dot product of vectors <Latex>{`$(1, 2, 3)$`}</Latex> and <Latex>{`$(4, -1, 5)$`}</Latex> is <Latex>{`$1 \\times 4 + 2 \\times (-1) + 3 \\times 5 = 15$`}</Latex>.</i></p>
            </div>
            <div className='group'>
                <h3>Cross Product</h3>
                <p>The <span className="emph">cross product</span> of two 3D vectors results in a new vector that is perpendicular to both. For vectors <Latex>{`$\\mathbf{v} = \\langle x_1, y_1, z_1 \\rangle$`}</Latex> and <Latex>{`$\\mathbf{w} = \\langle x_2, y_2, z_2 \\rangle$`}</Latex>, the cross product is given by:</p>
                <p><Latex>{`$$\\mathbf{v} \\times \\mathbf{w} = \\langle y_1z_2 - z_1y_2, z_1x_2 - x_1z_2, x_1y_2 - y_1x_2 \\rangle$$`}</Latex></p>
                <p><i>Example: The cross product of vectors <Latex>{`$(1, 2, 3)$`}</Latex> and <Latex>{`$(4, -1, 5)$`}</Latex> is <Latex>{`$\\langle 13, 7, -9 \\rangle$`}</Latex>.</i></p>
            </div>
            <div className='group'>
                <h3>Unit Vectors in 3D</h3>
                <p>A <span className="emph">unit vector</span> in 3D has a magnitude of 1 and points in a specific direction. To normalize a vector <Latex>{`$\\mathbf{v} = \\langle x, y, z \\rangle$`}</Latex>, divide it by its magnitude:</p>
                <p><Latex>{`$$\\mathbf{u} = \\frac{\\mathbf{v}}{|\\mathbf{v}|} = \\left\\langle \\frac{x}{|\\mathbf{v}|}, \\frac{y}{|\\mathbf{v}|}, \\frac{z}{|\\mathbf{v}|} \\right\\rangle$$`}</Latex></p>
                <p><i>Example: The unit vector in the direction of <Latex>{`$(3, 4, 5)$`}</Latex> is <Latex>{`$\\left( \\frac{3}{\\sqrt{50}}, \\frac{4}{\\sqrt{50}}, \\frac{5}{\\sqrt{50}} \\right)$`}</Latex>.</i></p>
            </div>
        </div>
    );
};

export default Vectors3D;
