import React, { useState, useEffect } from 'react';
import '../styles/DataStructs.css';

const getRandomArray = () => {
  const length = Math.floor(Math.random() * 7) + 4;
  const array = Array.from({ length }, () => Math.floor(Math.random() * 100));
  return array;
};

const CocktailShakerSort = () => {
  const [array, setArray] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [sorted, setSorted] = useState(false);
  const [iterations, setIterations] = useState([]);

  useEffect(() => {
    const initialArray = getRandomArray();
    setArray(initialArray);
    setIterations([initialArray]);
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputArray = () => {
    const newArray = inputValue.split(',').map(Number);
    setArray(newArray);
    setSorted(false);
    setIterations([newArray]);
  };

  const cocktailShakerSort = (arr) => {
    let sortedArray = [...arr];
    let n = sortedArray.length;
    let newIterations = [];
    let swapped = true;
    let start = 0;
    let end = n - 1;

    while (swapped) {
      swapped = false;

      for (let i = start; i < end; ++i) {
        if (sortedArray[i] > sortedArray[i + 1]) {
          [sortedArray[i], sortedArray[i + 1]] = [sortedArray[i + 1], sortedArray[i]];
          swapped = true;
        }
      }

      newIterations.push([...sortedArray]);

      if (!swapped) break;

      swapped = false;
      --end;

      for (let i = end - 1; i >= start; --i) {
        if (sortedArray[i] > sortedArray[i + 1]) {
          [sortedArray[i], sortedArray[i + 1]] = [sortedArray[i + 1], sortedArray[i]];
          swapped = true;
        }
      }

      newIterations.push([...sortedArray]);

      ++start;
    }

    return newIterations;
  };

  const handleSort = () => {
    const sortIterations = cocktailShakerSort(array);
    setIterations(sortIterations);
    setSorted(true);
  };

  return (
    <div>
      <h1>Cocktail Shaker Sort Visualizer</h1>
      <div className='controls-container'>
        <div className="controls">
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Enter comma separated values"
          />
          <button onClick={handleInputArray}>Set Array</button>
          <button onClick={handleSort}>Sort</button>
        </div>
      </div>
      <div className="algorithm-visualization">
        {iterations.map((iteration, iterIndex) => (
          <div key={iterIndex} className="iteration">
            {iteration.map((item, index) => (
              <div key={index} className="linkedlist-node">
                <div className="array-item">
                  {item ? item : '-'}
                  <div className="index-label">{index}</div>
                </div>
                {index < iteration.length - 1 && <div className="node-pointer"></div>}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CocktailShakerSort;
