import React, { useState, useEffect } from 'react';
import '../styles/DataStructs.css';

const getRandomDoublyLinkedList = () => {
  const length = Math.floor(Math.random() * 10) + 1;
  const array = Array.from({ length }, () => Math.floor(Math.random() * 100));
  return array;
};

const DoublyLinkedList = () => {
  const [doublyLinkedList, setDoublyLinkedList] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [indexValue, setIndexValue] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    setDoublyLinkedList(getRandomDoublyLinkedList());
  }, []);

  const handleAddFront = () => {
    setDoublyLinkedList([inputValue, ...doublyLinkedList]);
    setInputValue('');
  };

  const handleAddBack = () => {
    setDoublyLinkedList([...doublyLinkedList, inputValue]);
    setInputValue('');
  };

  const handleAddAtIndex = () => {
    const index = parseInt(indexValue, 10);
    if (index >= 0 && index <= doublyLinkedList.length) {
      const newList = [...doublyLinkedList];
      newList.splice(index, 0, inputValue);
      setDoublyLinkedList(newList);
      setInputValue('');
      setIndexValue('');
    } else {
      alert('Invalid index');
    }
  };

  const handleRemoveFront = () => {
    if (doublyLinkedList.length > 0) {
      setDoublyLinkedList(doublyLinkedList.slice(1));
    }
  };

  const handleRemoveBack = () => {
    if (doublyLinkedList.length > 0) {
      setDoublyLinkedList(doublyLinkedList.slice(0, -1));
    }
  };

  const handleRemoveAtIndex = () => {
    const index = parseInt(indexValue, 10);
    if (index >= 0 && index < doublyLinkedList.length) {
      const newList = [...doublyLinkedList];
      newList.splice(index, 1);
      setDoublyLinkedList(newList);
      setIndexValue('');
    } else {
      alert('Invalid index');
    }
  };

  const handleItemClick = (index) => {
    setSelectedIndex(index === selectedIndex ? null : index);
    setIndexValue(index === selectedIndex ? null : index);
  };

  return (
    <div>
      <h1>Doubly LinkedList Visualizer</h1>
      <div className='controls-container'>
        <div className="controls">
          <div>
            <input
              type="number"
              className='left-input'
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="value"
            />
            <button onClick={handleAddFront}>Add to Front</button>
          </div>
          <div>
            <input
              type="number"
              className='left-input'
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="value"
            />
            <button onClick={handleAddAtIndex}>Add at Index</button>
            <input
              className='right-input'
              type="number"
              value={indexValue}
              onChange={(e) => setIndexValue(e.target.value)}
              placeholder="index"
            />
          </div>
          <div>
            <input
              type="number"
              className='left-input'
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="value"
            />
            <button onClick={handleAddBack}>Add to Back</button>
          </div>
        </div>
        <div className="controls">
          <button onClick={handleRemoveFront}>Remove from Front</button>
          <div>
            <button onClick={handleRemoveAtIndex}>Remove at Index</button>
            <input
              className='right-input'
              type="number"
              value={indexValue}
              onChange={(e) => setIndexValue(e.target.value)}
              placeholder="index"
            />
          </div>
          <button onClick={handleRemoveBack}>Remove from Back</button>
        </div>
      </div>
      <div className="data-struct-visualization round">
        {doublyLinkedList.map((item, index) => (
          <div key={index} className="linkedlist-node">
            {index > 0 && <div className="node-double-pointer">↔</div>}
            <div 
                className={`array-item ${index === selectedIndex ? 'selected' : ''}`} 
                onClick={() => handleItemClick(index)}>
                {item ? item : '-'}
                <div className="index-label">{index}</div>
            </div>
            
          </div>
        ))}
      </div>
    </div>
  );
};

export default DoublyLinkedList;
