import React, { useState, useEffect } from 'react';

const keySignatureMap = {
  'C': [],
  'G': ['F#'],
  'D': ['F#', 'C#'],
  'A': ['F#', 'C#', 'G#'],
  'E': ['F#', 'C#', 'G#', 'D#'],
  'B': ['F#', 'C#', 'G#', 'D#', 'A#'],
  'F#': ['F#', 'C#', 'G#', 'D#', 'A#', 'E#'],
  'C#': ['F#', 'C#', 'G#', 'D#', 'A#', 'E#', 'B#'],
  'F': ['Bb'],
  'Bb': ['Bb', 'Eb'],
  'Eb': ['Bb', 'Eb', 'Ab'],
  'Ab': ['Bb', 'Eb', 'Ab', 'Db'],
  'Db': ['Bb', 'Eb', 'Ab', 'Db', 'Gb'],
  'Gb': ['Bb', 'Eb', 'Ab', 'Db', 'Gb', 'Cb']
};

const findKeyBySignature = (accidentals) => {
  for (const [key, value] of Object.entries(keySignatureMap)) {
    if (value.join(' ') === accidentals.join(' ')) {
      return key;
    }
  }
  return null;
};

const MajorKeys = () => {
  const [currentSignature, setCurrentSignature] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [status, setStatus] = useState('');

  const generateRandomSignature = () => {
    const keys = Object.keys(keySignatureMap);
    const randomKey = keys[Math.floor(Math.random() * keys.length)];
    setCurrentSignature(keySignatureMap[randomKey]);
  };

  useEffect(() => {
    generateRandomSignature();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const correctKey = findKeyBySignature(currentSignature);
    if (userInput.trim().toUpperCase() === correctKey?.toUpperCase()) {
      setStatus('Correct! Well done.');
      setTimeout(() => {
        generateRandomSignature();
        setUserInput('');
        setStatus('');
      }, 1000);
    } else {
      setStatus(`Incorrect. The correct answer is ${correctKey}. Try another one!`);
    }
  };

  return (
    <div>
      <h2>What is the major key for these accidentals?</h2>
      <p>{currentSignature.join(' ') || '(no accidentals)'}</p>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Type the major key"
        />
        <button type="submit">Check</button>
      </form>
      <p>{status}</p>
    </div>
  );
};

export default MajorKeys;