import React, { useState, useEffect } from 'react';
import '../styles/DataStructs.css';

const getRandomArray = () => {
  const length = Math.floor(Math.random() * 7) + 4;
  const array = Array.from({ length }, () => Math.floor(Math.random() * 100));
  return array;
};

const InsertionSort = () => {
  const [array, setArray] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [sorted, setSorted] = useState(false);
  const [iterations, setIterations] = useState([]);

  useEffect(() => {
    const initialArray = getRandomArray();
    setArray(initialArray);
    setIterations([initialArray]);
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputArray = () => {
    const newArray = inputValue.split(',').map(Number);
    setArray(newArray);
    setSorted(false);
    setIterations([newArray]);
  };

  const insertionSort = (arr) => {
    let sortedArray = [...arr];
    let newIterations = [[...sortedArray]];

    for (let i = 1; i < sortedArray.length; i++) {
      let key = sortedArray[i];
      let j = i - 1;

      while (j >= 0 && sortedArray[j] > key) {
        sortedArray[j + 1] = sortedArray[j];
        j = j - 1;
      }
      sortedArray[j + 1] = key;
      newIterations.push([...sortedArray]);
    }

    return newIterations;
  };

  const handleSort = () => {
    const sortIterations = insertionSort(array);
    setIterations(sortIterations);
    setSorted(true);
  };

  return (
    <div>
      <h1>Insertion Sort Visualizer</h1>
      <div className='controls-container'>
        <div className="controls">
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Enter comma separated values"
          />
          <button onClick={handleInputArray}>Set Array</button>
          <button onClick={handleSort}>Sort</button>
        </div>
      </div>
      <div className="algorithm-visualization">
        {iterations.map((iteration, iterIndex) => (
          <div key={iterIndex} className="iteration">
            {iteration.map((item, index) => (
              <div key={index} className="linkedlist-node">
                <div className={`array-item ${index <= iterIndex ? 'sorted' : ''}`}>
                  {item ? item : '-'}
                  <div className="index-label">{index}</div>
                </div>
                {index < iteration.length - 1 && <div className="node-pointer"></div>}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InsertionSort;
