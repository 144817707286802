import React, { useState, useEffect, useRef } from 'react';

const getRandomNumbersForDivision = (repeat) => {
  let divisor = Math.floor(Math.random() * 10) + 1; // dividend / divisor = quotient
  let quotient = Math.floor(Math.random() * 10) + 1;
  let dividend = divisor * quotient;
  if (repeat && dividend === repeat[0] && divisor === repeat[1]) return getRandomNumbersForDivision(repeat); // regenerate numbers if they are the same  
  return [dividend, divisor];
};

const Division = () => {
  const [[num1, num2], setNumbers] = useState(getRandomNumbersForDivision());
  const [answer, setAnswer] = useState('');
  const [status, setStatus] = useState('');
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [inputStyle, setInputStyle] = useState({ })
  const inputRef = useRef(null);

  useEffect(() => {
    setStatus('Practice division:');
    setInputStyle({ border: '2px solid black' });
    if (inputRef.current) {
      inputRef.current.disabled = false;     
      inputRef.current.focus();
    }
  }, [num1, num2]);

  const checkAnswer = (e) => {
    e.preventDefault();
    if (parseInt(answer) === num1 / num2) {
      setStatus('Good job!');
      setInputStyle({ border: '2px solid green' });
      setIsInputDisabled(true);
      setTimeout(() => {
        setNumbers(getRandomNumbersForDivision([num1, num2]));
        setAnswer('');
        setIsInputDisabled(false);
        setStatus('Practice division:');
        setInputStyle({ border: '2px solid black' });
      }, 750);
    } else {
      if (parseInt(answer) === num1 - num2) setStatus('Division, not subtraction!');
      else setStatus('Try again!');
      setInputStyle({ border: '2px solid red' });
      inputRef.current.select();
    }
  };

  return (
    <>      
      <form onSubmit={checkAnswer}>
        <p className='status'>{status}</p>
        <label>
          <span className='question'>{num1} / {num2} = </span>
          <input
            ref={inputRef}
            type="text"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            disabled={isInputDisabled}
            style={inputStyle}
          />
        </label>
        <button type="submit" disabled={isInputDisabled}>Submit</button>
      </form>
    </>
  );
};

export default Division;
