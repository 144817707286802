import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const MathematicalInduction = () => {
    return (
        <div>
            <h1>Mathematical Induction</h1>
            <div className='group'>
                <h3>Well-Ordering Principle</h3>
                <p>
                    The <span className='emph'>Well-Ordering Principle</span> states that given any non-empty set of positive integers, there is always a smallest number in the set.
                </p>
                <p><i>Example: Consider the set {"{3, 5, 9}"}. The smallest element in this set is 3.</i></p>
            </div>

            <div className='group'>
                <h3>Mathematical Induction</h3>
                <p>
                    <span className='emph'>Mathematical induction</span> is a method of proof used to establish that a statement is true for all natural numbers. It is based on two main steps:
                </p>
                <ul>
                    <li>
                        <b>Base Case</b>: Show that the statement is true for the initial value (usually 1).
                    </li>
                    <li>
                        <b>Inductive Step</b>: Assume the statement is true for some arbitrary natural number <Latex>$k$</Latex>, and then prove it is true for <Latex>$k + 1$</Latex>.
                    </li>
                </ul>
                <p>
                    If both the base case and the inductive step are proven, the statement is true for all natural numbers.
                </p>
                <p><i>Example: To prove that the sum of the first <Latex>$n$</Latex> positive integers is given by <Latex>{`$S(n) = \\frac{n(n+1)}{2}$`}</Latex>, we use mathematical induction.</i></p>
            </div>

            <div className='group'>
                <h3>Strong Induction</h3>
                <p>
                    <span className='emph'>Strong induction</span> is a variation of mathematical induction where, in the inductive step, you assume the statement is true for all values up to <Latex>$k$</Latex>, rather than just for <Latex>$k$</Latex>. Then, you prove the statement is true for <Latex>$k + 1$</Latex>.
                </p>
                <ul>
                    <li>
                        <b>Base Case</b>: Prove the statement for the initial value (usually 1).
                    </li>
                    <li>
                        <b>Inductive Step</b>: Assume the statement is true for all values <Latex>$1, 2, \dots, k$</Latex>. Then, prove it for <Latex>$k + 1$</Latex>.
                    </li>
                </ul>
                <p>
                    Strong induction is particularly useful when the proof for <Latex>$k + 1$</Latex> relies on multiple previous cases rather than just the immediate predecessor.
                </p>
                <p><i>Example: Proving properties of sequences, like the Fibonacci sequence, often uses strong induction.</i></p>
            </div>
        </div>
    );
};

export default MathematicalInduction;
