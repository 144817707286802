import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const InclusionExclusion = () => {
    return (
        <div>
            <h1>Principle of Inclusion-Exclusion</h1>
            <div className='group'>
                <h3>Introduction to Inclusion-Exclusion</h3>
                <p>
                    The <span className='emph'>Principle of Inclusion-Exclusion</span> (PIE) is a counting technique used to find the number of elements in the union of several sets. It helps in avoiding overcounting by subtracting the sizes of the intersections of the sets.
                </p>
                <p>
                    For two sets <Latex>$A$</Latex> and <Latex>$B$</Latex>, the principle states:
                </p>
                <p className='center'>
                    <Latex>{`$|A \\cup B| = |A| + |B| - |A \\cap B|$`}</Latex>
                </p>
                <p><i>Example: If <Latex>$|A| = 5$</Latex>, <Latex>$|B| = 7$</Latex>, and <Latex>{`$|A \\cap B| = 2$`}</Latex>, then <Latex>{`$|A \\cup B| = 5 + 7 - 2 = 10$`}</Latex>.</i></p>
            </div>
            <div className='group'>
                <h3>Inclusion-Exclusion for Three Sets</h3>
                <p>
                    For three sets <Latex>$A$</Latex>, <Latex>$B$</Latex>, and <Latex>$C$</Latex>, the principle extends as follows:
                </p>
                <p className='center'>
                    <Latex>{`$|A \\cup B \\cup C| = |A| + |B| + |C| - |A \\cap B| - |A \\cap C| - |B \\cap C| + |A \\cap B \\cap C|$`}</Latex>
                </p>
                <p>
                    This formula accounts for the overcounting of the pairwise intersections and then adds back the size of the triple intersection, which was subtracted too many times.
                </p>
                <p><i>Example: If <Latex>$|A| = 5$</Latex>, <Latex>$|B| = 7$</Latex>, <Latex>$|C| = 6$</Latex>, <Latex>{`$|A \\cap B| = 2$`}</Latex>, <Latex>{`$|A \\cap C| = 1$`}</Latex>, <Latex>{`$|B \\cap C| = 3$`}</Latex>, and <Latex>{`$|A \\cap B \\cap C| = 1$`}</Latex>, then:</i></p>
                <p className='center'>
                    <Latex>{`$|A \\cup B \\cup C| = 5 + 7 + 6 - 2 - 1 - 3 + 1 = 13$`}</Latex>
                </p>
            </div>
            <div className='group'>
                <h3>General Inclusion-Exclusion Principle</h3>
                <p>
                    The general form of the Principle of Inclusion-Exclusion for <Latex>$n$</Latex> sets <Latex>{`$A_1, A_2, \\dots, A_n$`}</Latex> is:
                </p>
                <p className='center'>
                    <Latex>{`$|A_1 \\cup A_2 \\cup \\dots \\cup A_n| = \\sum_{i=1}^{n} |A_i| - \\sum_{1 \\leq i < j \\leq n} |A_i \\cap A_j| + \\sum_{1 \\leq i < j < k \\leq n} |A_i \\cap A_j \\cap A_k| - \\dots + (-1)^{n+1} |A_1 \\cap A_2 \\cap \\dots \\cap A_n|$`}</Latex>
                </p>
                <p>
                    This formula alternates between adding and subtracting the sizes of intersections of increasing size, ensuring that all overlaps are counted correctly.
                </p>
                <p><i>Example: For four sets <Latex>$A$, $B$, $C$, and $D$</Latex>, the formula would include terms up to the intersection of all four sets.</i></p>
            </div>
        </div>
    );
};

export default InclusionExclusion;
