import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const RRFirstOrderLinear = () => {
    return (
        <div>
            <h1>Recurrence Relations: First-Order Linear</h1>
            <div className='group'>
                <h3>Recurrence Relations</h3>
                <p>
                    A <span className='emph'>recurrence relation</span> is an equation that defines a sequence of numbers where each term is expressed as a function of one or more previous terms.
                </p>
            </div>
            <div className='group'>
                <h3>First-Order Linear Recurrence Relation</h3>
                <p>
                    A <span className='emph'>first-order linear recurrence relation</span> has the general form:
                </p>
                <p className='center'>
                    <Latex>{`$a_n = c_1 a_{n-1} + f(n)$`}</Latex>
                </p>
                <p>
                    Here, <Latex>{`$c_1$`}</Latex> is a constant, and <Latex>{`$f(n)$`}</Latex> is a function of <Latex>{`$n$`}</Latex>. If <Latex>{`$f(n) = 0$`}</Latex>, the relation is called <span className='emph'>homogeneous</span>; otherwise, it is <span className='emph'>non-homogeneous</span>.
                </p>
            </div>
            <div className='group'>
                <h3>Solving First-Order Linear Recurrence Relations</h3>
                <p>
                    To solve a first-order linear recurrence relation, we can use the following approach:
                </p>
                <ol>
                    <li><b>Find the general solution:</b> For the homogeneous case, where <Latex>{`$f(n) = 0$`}</Latex>, the solution has the form <Latex>{`$a_n = c_1^n a_0$`}</Latex>, where <Latex>{`$a_0$`}</Latex> is the initial condition.</li>
                    <li><b>Particular solution:</b> For the non-homogeneous case, find a particular solution that satisfies the non-homogeneous equation.</li>
                    <li><b>Combine solutions:</b> The general solution is the sum of the homogeneous and particular solutions.</li>
                </ol>
                <p>
                    For example, consider the recurrence relation:
                </p>
                <p className='center'>
                    <Latex>{`$a_n = 2a_{n-1} + 3$`}</Latex>
                </p>
                <p>
                    The homogeneous part is <Latex>{`$a_n = 2a_{n-1}$`}</Latex>, and the particular solution is <Latex>{`$a_n = -3$`}</Latex>. So, the general solution is:
                </p>
                <p className='center'>
                    <Latex>{`$a_n = 2^n a_0 - 3$`}</Latex>
                </p>
                <p>
                    Using the initial condition, you can solve for <Latex>{`$a_0$`}</Latex> to find the specific solution.
                </p>
            </div>
        </div>
    );
};

export default RRFirstOrderLinear;
