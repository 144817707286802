import React, { useState, useEffect } from 'react';
import '../styles/DataStructs.css';

const getRandomArray = () => {
  const length = Math.floor(Math.random() * 10) + 1; // Random length between 1 and 10
  const array = Array.from({ length }, () => Math.floor(Math.random() * 100)); // Random numbers between 0 and 99
  return array;
};

const ArrayList = () => {
  const [arrayList, setArrayList] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [indexValue, setIndexValue] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    setArrayList(getRandomArray());
  }, []);

  const handleAddFront = () => {
    setArrayList([inputValue, ...arrayList]);
    setInputValue('');
  };

  const handleAddBack = () => {
    setArrayList([...arrayList, inputValue]);
    setInputValue('');
  };

  const handleAddAtIndex = () => {
    const index = parseInt(indexValue, 10);
    if (index >= 0 && index <= arrayList.length) {
      const newList = [...arrayList];
      newList.splice(index, 0, inputValue);
      setArrayList(newList);
      setInputValue('');
      setIndexValue('');
    } else {
      alert('Invalid index');
    }
  };

  const handleRemoveFront = () => {
    if (arrayList.length > 0) {
      setArrayList(arrayList.slice(1));
    }
  };

  const handleRemoveBack = () => {
    if (arrayList.length > 0) {
      setArrayList(arrayList.slice(0, -1));
    }
  };

  const handleRemoveAtIndex = () => {
    const index = parseInt(indexValue, 10);
    if (index >= 0 && index < arrayList.length) {
      const newList = [...arrayList];
      newList.splice(index, 1);
      setArrayList(newList);
      setIndexValue('');
    } else {
      alert('Invalid index');
    }
  };

  const handleItemClick = (index) => {
    setSelectedIndex(index === selectedIndex ? null : index);
    setIndexValue(index === selectedIndex ? null : index);
  };

  return (
    <div>
      <h1>ArrayList Visualizer</h1>
      <div className='controls-container'>
        <div className="controls">
          <div>
            <input
              type="number"
              className='left-input'
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="value"
            />
            <button onClick={handleAddFront}>Add to Front</button>
          </div>
          <div>
            <input
              type="number"
              className='left-input'
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="value"
            />
            <button onClick={handleAddAtIndex}>Add at Index</button>
            <input
              className='right-input'
              type="number"
              value={indexValue}
              onChange={(e) => setIndexValue(e.target.value)}
              placeholder="index"
            />
          </div>
          <div>
            <input
              type="number"
              className='left-input'
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="value"
            />
            <button onClick={handleAddBack}>Add to Back</button>
          </div>
        </div>
        <div className="controls">
          <button onClick={handleRemoveFront}>Remove from Front</button>
          <div>
            <button onClick={handleRemoveAtIndex}>Remove at Index</button>
            <input
              className='right-input'
              type="number"
              value={indexValue}
              onChange={(e) => setIndexValue(e.target.value)}
              placeholder="index"
            />
          </div>
          <button onClick={handleRemoveBack}>Remove from Back</button>
        </div>
      </div>
      <div className="data-struct-visualization round">
        {arrayList.map((item, index) => (
          <div
            key={index}
            className={`array-item ${index === selectedIndex ? 'selected' : ''}`}
            onClick={() => handleItemClick(index)}
          >
            <div>{item ? item : '-'}</div>
            <div className="index-label">{index}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArrayList;
