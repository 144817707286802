import React, { useState, useEffect } from 'react';
import '../styles/DataStructs.css';

const BruteForcePatternMatching = () => {
  const [text, setText] = useState('');
  const [pattern, setPattern] = useState('');
  const [matches, setMatches] = useState([]);
  const [iterations, setIterations] = useState([]);
  const [index, setIndex] = useState('');

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handlePatternChange = (e) => {
    setPattern(e.target.value);
  };

  const bruteForceSearch = (txt, pat) => {
    let N = txt.length;
    let M = pat.length;
    let iter = [];
    let result = [];

    for (let i = 0; i <= N - M; i++) {
      let iteration = Array(N).fill(null);
      for (let j = 0; j < M; j++) {
        if (txt[i + j] === pat[j]) {
          iteration[i + j] = 'matched';
        } else {
          iteration[i + j] = 'not-matched';
        }
      }
      iter.push([...iteration]);
      if (!iteration.includes('not-matched')) {
        result.push(i);
      }
    }
    setIterations(iter);
    return result;
  };

  const handleSearch = () => {
    const result = bruteForceSearch(text, pattern);
    setMatches(result);
  };

  return (
    <div>
      <h1>Brute Force Pattern Matching Visualizer</h1>
      <div className='controls-container'>
        <div className="controls">
          <input
            type="text"
            value={text}
            onChange={handleTextChange}
            placeholder="Enter text"
          />
          <input
            type="text"
            value={pattern}
            onChange={handlePatternChange}
            placeholder="Enter pattern"
          />
          <button onClick={handleSearch}>Search</button>
        </div>
      </div>
      <div className="algorithm-visualization">
        <div className="grid-row">
          {text.split('').map((_, index) => (
            <span key={index} className="index-label grid-cell">
              {index}
            </span>
          ))}
        </div>
        <div className="grid-row">
          {text.split('').map((char, index) => (
            <span key={index} className="grid-cell grid-header">
              {char}
            </span>
          ))}
        </div>
        {iterations.map((iteration, iterIndex) => (
          <div key={iterIndex} className="grid-row">
            {iteration.map((state, index) => (
              <span key={index} className={`grid-cell ${state || ''}`}>
                {index >= iterIndex && index < iterIndex + pattern.length ? pattern[index - iterIndex] : ''}
              </span>
            ))}
          </div>
        ))}
      </div>
      <div className="matches">
        {matches.length > 0 ? (
          <p>Pattern found at indices: {matches.join(', ')}</p>
        ) : (
          <p>No matches found</p>
        )}
      </div>
    </div>
  );
};

export default BruteForcePatternMatching;
