import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const PartitionsOfIntegers = () => {
    return (
        <div>
            <h1>Partitions of Integers</h1>
            <div className='group'>
                <h3>Partitions of Integers</h3>
                <p>
                    A <span className='emph'>partition</span> of a positive integer <Latex>{`$n$`}</Latex> is a way of writing <Latex>{`$n$`}</Latex> as a sum of positive integers, where the order of the summands does not matter. Each distinct way of writing <Latex>{`$n$`}</Latex> as a sum represents a different partition.
                </p>
                <p><i>Example: The partitions of the integer 4 are:</i></p>
                <p className='center'>
                    <Latex>{`$4 = 4$`}</Latex><br />
                    <Latex>{`$4 = 3 + 1$`}</Latex><br />
                    <Latex>{`$4 = 2 + 2$`}</Latex><br />
                    <Latex>{`$4 = 2 + 1 + 1$`}</Latex><br />
                    <Latex>{`$4 = 1 + 1 + 1 + 1$`}</Latex>
                </p>
                <p>
                    So, the integer 4 has 5 partitions.
                </p>
            </div>
            <div className='group'>
                <h3>Partition Function</h3>
                <p>
                    The <span className='emph'>partition function</span>, denoted by <Latex>{`$p(n)$`}</Latex>, gives the number of distinct partitions of the integer <Latex>{`$n$`}</Latex>. For example, as we saw above, <Latex>{`$p(4) = 5$`}</Latex>.
                </p>
                <p>
                    The partition function grows rapidly with <Latex>{`$n$`}</Latex>, and there is no simple closed-form formula for it. However, it can be computed using generating functions or recurrence relations.
                </p>
            </div>
            <div className='group'>
                <h3>Generating Function for Partitions</h3>
                <p>
                    The generating function for the partition function <Latex>{`$p(n)$`}</Latex> is given by the infinite product:
                </p>
                <p className='center'>
                    <Latex>{`$P(x) = \\prod_{k=1}^{\\infty} \\frac{1}{1 - x^k}$`}</Latex>
                </p>
                <p>
                    Expanding this product yields the generating function for the sequence <Latex>{`$p(1), p(2), p(3), \\dots$`}</Latex>.
                </p>
            </div>
            <div className='group'>
                <h3>Restricted Partitions</h3>
                <p>
                    Partitions can be restricted by imposing additional conditions on the summands. For example:
                </p>
                <ul>
                    <li><b>Partitions into distinct parts:</b> Where all summands are different. For example, the partitions of 5 into distinct parts are <Latex>{`$5 = 5$`}</Latex>, <Latex>{`$5 = 4 + 1$`}</Latex>, and <Latex>{`$5 = 3 + 2$`}</Latex>.</li>
                    <li><b>Partitions into odd parts:</b> Where all summands are odd numbers. For example, the partitions of 5 into odd parts are <Latex>{`$5 = 5$`}</Latex>, <Latex>{`$5 = 3 + 1 + 1$`}</Latex>, and <Latex>{`$5 = 1 + 1 + 1 + 1 + 1$`}</Latex>.</li>
                </ul>
            </div>
        </div>
    );
};

export default PartitionsOfIntegers;
