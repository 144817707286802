import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const LineEquations = () => {
    return (
        <div>
            <h1>Equations of Lines in Space</h1>
            <div className='group'>
                <h3>Parametric Equations of a Line</h3>
                <p>If a line in 3D space passes through a point <Latex>{`$P_0(x_0, y_0, z_0)$`}</Latex> and has direction vector <Latex>{`$\\mathbf{v} = \\langle a, b, c \\rangle$`}</Latex>, its parametric equations are:</p>
                <p><Latex>{`$$x = x_0 + at$$`}</Latex></p>
                <p><Latex>{`$$y = y_0 + bt$$`}</Latex></p>
                <p><Latex>{`$$z = z_0 + ct$$`}</Latex></p>
                <p>Here, <Latex>{`$t$`}</Latex> is a parameter that varies over all real numbers.</p>
                <p><i>Example: For a line passing through <Latex>{`$(1, 2, 3)$`}</Latex> with direction vector <Latex>{`$\\langle 2, -1, 3 \\rangle$`}</Latex>, the parametric equations are <Latex>{`$x = 1 + 2t$`}</Latex>, <Latex>{`$y = 2 - t$`}</Latex>, <Latex>{`$z = 3 + 3t$`}</Latex>.</i></p>
            </div>
            <div className='group'>
                <h3>Symmetric Equations of a Line</h3>
                <p>The symmetric equations of a line in 3D space are derived from the parametric equations by eliminating the parameter <Latex>{`$t$`}</Latex>:</p>
                <p><Latex>{`$$\\frac{x - x_0}{a} = \\frac{y - y_0}{b} = \\frac{z - z_0}{c}$$`}</Latex></p>
                <p>These equations represent the same line as the parametric form.</p>
                <p><i>Example: For the line with parametric equations <Latex>{`$x = 1 + 2t$`}</Latex>, <Latex>{`$y = 2 - t$`}</Latex>, <Latex>{`$z = 3 + 3t$`}</Latex>, the symmetric equations are <Latex>{`$\\frac{x - 1}{2} = \\frac{y - 2}{-1} = \\frac{z - 3}{3}$`}</Latex></i></p>
            </div>
            <div className='group'>
                <h3>Vector Equation of a Line</h3>
                <p>Given a point <Latex>{`$P_0(x_0, y_0, z_0)$`}</Latex> on the line and a direction vector <Latex>{`$\\mathbf{v} = \\langle a, b, c \\rangle$`}</Latex>, the vector equation is:</p>
                <p><Latex>{`$$\\mathbf{r}(t) = \\mathbf{r}_0 + t\\mathbf{d}$$`}</Latex></p>
                <p>where <Latex>{`$\\mathbf{r}(t)$`}</Latex> is the position vector <Latex>{`$\\langle x(t), y(t), z(t) \\rangle$`}</Latex> and <Latex>{`$\\mathbf{r}_0$`}</Latex> is the position vector of the point <Latex>{`$P_0$`}</Latex>.</p>
                <p><i>Example: For a line passing through <Latex>{`$(1, 2, 3)$`}</Latex> with direction vector <Latex>{`$\\langle 2, -1, 3 \\rangle$`}</Latex>, the vector equation is <Latex>{`$$\\mathbf{r}(t) = \\langle 1, 2, 3 \\rangle + t\\langle 2, -1, 3 \\rangle$$`}</Latex></i></p>
            </div>
            <div className='group'>
                <h3>Intersection of Two Lines</h3>
                <p>To determine if two lines intersect, you can set their parametric equations equal to each other and solve for the parameters. If a solution exists, the lines intersect at that point.</p>
                <p><i>Example: Consider the lines <Latex>{`$$x = 1 + t, y = 2 + 2t, z = 3 + 3t$$`}</Latex> and <Latex>{`$$x = 4 + s, y = 2 + s, z = 6 + 4s$$`}</Latex> Solving these equations simultaneously gives the intersection point if one exists.</i></p>
            </div>
            <div className='group'>
                <h3>Skew Lines</h3>
                <p>In 3D space, two lines are called <span className="emph">skew lines</span> if they are neither parallel nor do they intersect.</p>
                <p><i>Example: The lines <Latex>{`$$x = 1 + t, y = 2 + 2t, z = 3 + 3t$$`}</Latex> and <Latex>{`$$x = 2 + s, y = -1 + s, z = 1 + 4s$$`}</Latex> are skew lines because they are not parallel and do not intersect.</i></p>
            </div>
        </div>
    );
};

export default LineEquations;
