import React, { useState } from 'react';
import '../styles/DataStructs.css';

class TreeNode {
  constructor(value) {
    this.value = value;
    this.left = null;
    this.right = null;
  }
}

const insertNode = (root, value) => {
  if (root === null) {
    return new TreeNode(value);
  }
  if (value < root.value) {
    root.left = insertNode(root.left, value);
  } else if (value > root.value) {
    root.right = insertNode(root.right, value);
  }
  return root;
};

const BinarySearchTree = () => {
  const [root, setRoot] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const handleInsert = () => {
    if (inputValue === '') return;
    const value = parseInt(inputValue, 10);
    setRoot(insertNode(root, value));
    setInputValue('');
  };

  const renderTree = (node) => {
    if (node === null) {
      return null;
    }
    return (
      <div className="tree-node">
        <div className="node-value">{node.value}</div>
        <div className="children">
          <div className="child">{renderTree(node.left)}</div>
          <div className="child">{renderTree(node.right)}</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h1>NOTE: FIX THIS SHIT</h1>
      <div className='controls-container'>
        <div className="controls">
          <input
            type="number"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="value"
          />
          <button onClick={handleInsert}>Insert</button>
        </div>
      </div>
      <div className="data-struct-visualization tree">
        {renderTree(root)}
      </div>
    </div>
  );
};

export default BinarySearchTree;
