import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const RRNonhomogeneous = () => {
    return (
        <div>
            <h1>Recurrence Relations: Nonhomogeneous</h1>
            <div className='group'>
                <h3>Nonhomogeneous Recurrence Relations</h3>
                <p>
                    A <span className='emph'>nonhomogeneous recurrence relation</span> is a recurrence relation in which the current term of the sequence depends on previous terms, along with an additional function or constant term. The general form of a nonhomogeneous recurrence relation is:
                </p>
                <p className='center'>
                    <Latex>{`$a_n = c_1 a_{n-1} + c_2 a_{n-2} + \\dots + c_k a_{n-k} + f(n)$`}</Latex>
                </p>
                <p>
                    Here, <Latex>{`$f(n)$`}</Latex> is the nonhomogeneous part, which can be any function of <Latex>{`$n$`}</Latex> (e.g., a polynomial, exponential, trigonometric, or constant function).
                </p>
            </div>
            <div className='group'>
                <h3>Solving Nonhomogeneous Recurrence Relations</h3>
                <p>
                    The solution to a nonhomogeneous recurrence relation is the sum of two parts:
                </p>
                <ul>
                    <li><b>Homogeneous solution:</b> The solution to the associated homogeneous recurrence relation (where <Latex>{`$f(n) = 0$`}</Latex>).</li>
                    <li><b>Particular solution:</b> A specific solution to the nonhomogeneous recurrence relation that satisfies the entire equation.</li>
                </ul>
                <p>
                    The general solution is given by:
                </p>
                <p className='center'>
                    <Latex>{`$a_n = a_n^{(h)} + a_n^{(p)}$`}</Latex>
                </p>
                <p>
                    where <Latex>{`$a_n^{(h)}$`}</Latex> is the homogeneous solution and <Latex>{`$a_n^{(p)}$`}</Latex> is the particular solution.
                </p>
            </div>
            <div className='group'>
                <h3>Method of Undetermined Coefficients</h3>
                <p>
                    One common method for finding a particular solution to a nonhomogeneous recurrence relation is the <span className='emph'>method of undetermined coefficients</span>. This involves guessing the form of the particular solution based on the form of <Latex>{`$f(n)$`}</Latex> and then determining the coefficients that satisfy the recurrence relation.
                </p>
                <p><i>Example: Consider the nonhomogeneous recurrence relation:</i></p>
                <p className='center'>
                    <Latex>{`$a_n = 2a_{n-1} + 3^n$`}</Latex>
                </p>
                <p>
                    The associated homogeneous solution is <Latex>{`$a_n^{(h)} = A 2^n$`}</Latex>. For the particular solution, guess <Latex>{`$a_n^{(p)} = B 3^n$`}</Latex> (since the nonhomogeneous term is <Latex>\( 3^n \)</Latex>). Substituting this guess into the recurrence relation and solving for <Latex>\( B \)</Latex> gives the particular solution.
                </p>
                <p>
                    The general solution is then:
                </p>
                <p className='center'>
                    <Latex>{`$a_n = A 2^n + B 3^n$`}</Latex>
                </p>
            </div>
            <div className='group'>
                <h3>Method of Generating Functions</h3>
                <p>
                    Another powerful method for solving nonhomogeneous recurrence relations is the <span className='emph'>method of generating functions</span>. This approach transforms the recurrence relation into an algebraic equation by introducing a generating function for the sequence.
                </p>
                <p>
                    Given a sequence <Latex>{`$\\{a_n\\}$`}</Latex>, the generating function <Latex>{`$A(x)$`}</Latex> is defined as:
                </p>
                <p className='center'>
                    <Latex>{`$A(x) = \\sum_{n=0}^{\\infty} a_n x^n$`}</Latex>
                </p>
                <p>
                    Substituting the recurrence relation into the generating function and manipulating the resulting series allows us to solve for <Latex>{`$A(x)$`}</Latex>, from which the sequence <Latex>{`$\\{a_n\\}$`}</Latex> can be recovered.
                </p>
                <p><i>Example: Consider the nonhomogeneous recurrence relation:</i></p>
                <p className='center'>
                    <Latex>{`$a_n = a_{n-1} + 1$`}</Latex> with initial condition <Latex>\( a_0 = 0 \)</Latex>.
                </p>
                <p>
                    The generating function is:
                </p>
                <p className='center'>
                    <Latex>{`$A(x) = \\sum_{n=0}^{\\infty} a_n x^n$`}</Latex>
                </p>
                <p>
                    By substituting the recurrence relation and simplifying, we find that:
                </p>
                <p className='center'>
                    <Latex>{`$A(x) = \\frac{x}{(1 - x)^2}$`}</Latex>
                </p>
                <p>
                    Expanding this generating function as a power series gives the sequence <Latex>{`$a_n = n$`}</Latex>.
                </p>
            </div>
        </div>
    );
};

export default RRNonhomogeneous;
