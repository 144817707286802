import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const GreatestCommonDivisor = () => {
    return (
        <div>
            <h1>Greatest Common Divisor (GCD)</h1>

            <div className='group'>
                <h3>Greatest Common Divisor</h3>
                <p>
                    The <span className='emph'>Greatest Common Divisor</span> (GCD), also known as the greatest common factor (GCF), of two or more integers is the largest positive integer that divides each of the integers without leaving a remainder.
                </p>
                <p>
                    <i>For example, the GCD of 12 and 18 is 6, since 6 is the largest number that divides both 12 and 18 evenly.</i>
                </p>
            </div>

            <div className='group'>
                <h3>Prime Factorization</h3>
                <p>
                    This method involves finding the prime factors of each number and then taking the product of the smallest powers of the common factors.
                </p>
                <p><i>Example: Find the GCD of 48 and 60.</i></p>
                <ul>
                    <li>Prime factors of 48: <Latex>$48 = 2^4 \times 3^1$</Latex></li>
                    <li>Prime factors of 60: <Latex>$60 = 2^2 \times 3^1 \times 5^1$</Latex></li>
                </ul>
                <p>The common prime factors are 2 and 3. The smallest powers are <Latex>$2^2$</Latex> and <Latex>$3^1$</Latex>, so:</p>
                <p style={{ textAlign: 'center' }}>
                    <Latex>{`$\\text{GCD}(48, 60) = 2^2 \\times 3^1 = 4 \\times 3 = 12$`}</Latex>
                </p>
            </div>

<div className='group'>
    <h3>Euclidean Algorithm</h3>
    <p>
        The Euclidean algorithm is a more efficient method to calculate the GCD. It is based on the principle that the GCD of two numbers also divides their difference. The algorithm is as follows:
    </p>
    <ul>
        <li>Start with two numbers, say <Latex>$a$</Latex> and <Latex>$b$</Latex>, where <Latex>{`$a > b$`}</Latex>.</li>
        <li>Replace <Latex>$a$</Latex> with <Latex>$a - b$</Latex>.</li>
        <li>Repeat until <Latex>$a = b$</Latex>, at which point the GCD is <Latex>$a$</Latex>.</li>
        <li>Alternatively, the algorithm can be implemented using division: <Latex>{`$\\text{GCD}(a, b) = \\text{GCD}(b, a \\mod b)$`}</Latex> until <Latex>$b = 0$</Latex>. The GCD is then <Latex>$a$</Latex>.</li>
    </ul>
    <p><i>Example: Find the GCD of 48 and 18 using the Euclidean algorithm.</i></p>
    <ul>
        <li><Latex>{`$\\text{GCD}(48, 18) = \\text{GCD}(18, 48 \\mod 18) = \\text{GCD}(18, 12)$`}</Latex></li>
        <li><Latex>{`$\\text{GCD}(18, 12) = \\text{GCD}(12, 18 \\mod 12) = \\text{GCD}(12, 6)$`}</Latex></li>
        <li><Latex>{`$\\text{GCD}(12, 6) = \\text{GCD}(6, 12 \\mod 6) = \\text{GCD}(6, 0) = 6$`}</Latex></li>
    </ul>
    <p>So, the GCD of 48 and 18 is 6.</p>
</div>
        </div>
    );
};

export default GreatestCommonDivisor;
