import React, { useRef } from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';
import { Canvas, extend, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { ParametricGeometry } from 'three-stdlib';
import * as THREE from 'three';

extend({ ParametricGeometry });

const ParametricMesh = () => {
    const ref = useRef();
    
    // Example parametric function for a hyperboloid
    const hyperboloid = (u, v, target) => {
        const a = 1;  // adjust parameters to modify the shape
        const b = 1;
        const c = 1;
        const x = a * Math.sinh(u) * Math.cos(v);
        const y = b * Math.sinh(u) * Math.sin(v);
        const z = c * Math.cosh(u);
        target.set(x, y, z);
    };

    return (
        <mesh ref={ref}>
            <parametricGeometry args={[hyperboloid, 20, 20]} />
            <meshStandardMaterial color="blue" wireframe={true} />
        </mesh>
    );
};

const QuadricSurfaces = () => {

    return (
        <div>
            <h1>Quadric Surfaces</h1>
            <div className='group'>
                <h3>Quadric Surfaces</h3>
                <p>Quadric surfaces are the graphs of second-degree equations in three variables <Latex>{`$x$`}</Latex>, <Latex>{`$y$`}</Latex>, and <Latex>{`$z$`}</Latex>. They represent various types of 3D surfaces, such as ellipsoids, paraboloids, and hyperboloids.</p>
                <p>The general equation of a quadric surface is:</p>
                <p><Latex>{`$$Ax^2 + By^2 + Cz^2 + Dxy + Exz + Fyz + Gx + Hy + Iz + J = 0$$`}</Latex></p>
                <p>Where <Latex>{`$A$`}</Latex>, <Latex>{`$B$`}</Latex>, <Latex>{`$C$`}</Latex>, and other coefficients define the specific type of surface.</p>
            </div>
            <div className='group'>
                <h3>Ellipsoid</h3>
                <p>An <span className="emph">ellipsoid</span> is a quadric surface represented by the equation:</p>
                <p><Latex>{`$$\\frac{x^2}{a^2} + \\frac{y^2}{b^2} + \\frac{z^2}{c^2} = 1$$`}</Latex></p>
                <p>Where <Latex>{`$a$`}</Latex>, <Latex>{`$b$`}</Latex>, and <Latex>{`$c$`}</Latex> are the semi-axes lengths of the ellipsoid along the x, y, and z axes.</p>
                <p><i>Example: The surface <Latex>{`$\\frac{x^2}{4} + \\frac{y^2}{9} + \\frac{z^2}{16} = 1$`}</Latex> is an ellipsoid with semi-axes of 2, 3, and 4 along the x, y, and z axes, respectively.</i></p>
                <Canvas className='canvas'>
                    <ambientLight intensity={0.5} />
                    <pointLight position={[10, 10, 10]} />
                    <axesHelper args={[5]} /> {/* Add the AxesHelper here */}
                    <ParametricMesh />
                    <OrbitControls />
                </Canvas>
            </div>
            <div className='group'>
                <h3>Hyperboloid of One Sheet</h3>
                <p>A <span className="emph">hyperboloid of one sheet</span> is represented by the equation:</p>
                <p><Latex>{`$$\\frac{x^2}{a^2} + \\frac{y^2}{b^2} - \\frac{z^2}{c^2} = 1$$`}</Latex></p>
                <p>This surface has a saddle shape and is symmetric about the three coordinate axes.</p>
                <p><i>Example: The surface <Latex>{`$\\frac{x^2}{4} + \\frac{y^2}{9} - \\frac{z^2}{16} = 1$`}</Latex> is a hyperboloid of one sheet.</i></p>
            </div>
            <div className='group'>
                <h3>Hyperboloid of Two Sheets</h3>
                <p>A <span className="emph">hyperboloid of two sheets</span> is represented by the equation:</p>
                <p><Latex>{`$$-\\frac{x^2}{a^2} - \\frac{y^2}{b^2} + \\frac{z^2}{c^2} = 1$$`}</Latex></p>
                <p>This surface consists of two disconnected sheets.</p>
                <p><i>Example: The surface <Latex>{`$-\\frac{x^2}{4} - \\frac{y^2}{9} + \\frac{z^2}{16} = 1$`}</Latex> is a hyperboloid of two sheets.</i></p>
            </div>
            <div className='group'>
                <h3>Elliptic Paraboloid</h3>
                <p>An <span className="emph">elliptic paraboloid</span> is represented by the equation:</p>
                <p><Latex>{`$$\\frac{x^2}{a^2} + \\frac{y^2}{b^2} = \\frac{z}{c}$$`}</Latex></p>
                <p>This surface has a parabolic shape and opens in the direction of the z-axis.</p>
                <p><i>Example: The surface <Latex>{`$\\frac{x^2}{4} + \\frac{y^2}{9} = \\frac{z}{1}$`}</Latex> is an elliptic paraboloid.</i></p>
            </div>
            <div className='group'>
                <h3>Hyperbolic Paraboloid</h3>
                <p>A <span className="emph">hyperbolic paraboloid</span> is represented by the equation:</p>
                <p><Latex>{`$$\\frac{x^2}{a^2} - \\frac{y^2}{b^2} = \\frac{z}{c}$$`}</Latex></p>
                <p>This surface has a saddle shape and is commonly referred to as a "saddle surface."</p>
                <p><i>Example: The surface <Latex>{`$\\frac{x^2}{4} - \\frac{y^2}{9} = \\frac{z}{1}$`}</Latex> is a hyperbolic paraboloid.</i></p>
            </div>
            <div className='group'>
                <h3>Cone</h3>
                <p>A <span className="emph">cone</span> is represented by the equation:</p>
                <p><Latex>{`$$\\frac{x^2}{a^2} + \\frac{y^2}{b^2} = \\frac{z^2}{c^2}$$`}</Latex></p>
                <p>This surface extends infinitely in both directions along the z-axis, forming a double cone.</p>
                <p><i>Example: The surface <Latex>{`$\\frac{x^2}{4} + \\frac{y^2}{9} = \\frac{z^2}{16}$`}</Latex> represents a cone.</i></p>
            </div>
            <div className='group'>
                <h3>Elliptic Cylinder</h3>
                <p>An <span className="emph">elliptic cylinder</span> is represented by the equation:</p>
                <p><Latex>{`$$\\frac{x^2}{a^2} + \\frac{y^2}{b^2} = 1$$`}</Latex></p>
                <p>This surface extends infinitely along the z-axis and has elliptical cross-sections.</p>
                <p><i>Example: The surface <Latex>{`$\\frac{x^2}{4} + \\frac{y^2}{9} = 1$`}</Latex> is an elliptic cylinder.</i></p>
            </div>
        </div>
    );
};

export default QuadricSurfaces;
