import React, { useState, useEffect, useRef } from 'react';

const getRandomNumbersForSubtraction = (repeat) => {
  let num1 = Math.floor(Math.random() * 10) + 1;
  let num2 = Math.floor(Math.random() * num1); // Ensure num2 is less than or equal to num1
  if (repeat && num1 === repeat[0] && num2 === repeat[1]) {
    return getRandomNumbersForSubtraction(repeat); // Ensure new numbers if the same as previous
  }
  return [num1, num2];
};

const Subtraction = () => {
  const [[num1, num2], setNumbers] = useState(getRandomNumbersForSubtraction());
  const [answer, setAnswer] = useState('');
  const [status, setStatus] = useState('');
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [inputStyle, setInputStyle] = useState({});
  const inputRef = useRef(null);

  useEffect(() => {
    setStatus('Practice subtraction:');
    setInputStyle({ border: '2px solid black' });
    if (inputRef.current) {
      inputRef.current.disabled = false;
      inputRef.current.focus();
    }
  }, [num1, num2]);

  const checkAnswer = (e) => {
    e.preventDefault();
    if (parseInt(answer) === num1 - num2) {
      setStatus('Good job!');
      setInputStyle({ border: '2px solid green' });
      setIsInputDisabled(true); // Disable input to hide cursor
      setTimeout(() => {
        setNumbers(getRandomNumbersForSubtraction([num1, num2]))
        setAnswer(''); // Reset answer field when numbers change
        setIsInputDisabled(false);
      }, 500);
    } else {
      if (parseInt(answer) === num1 + num2) setStatus("Subtraction, not addition!");
      else setStatus('Try again!');
      setInputStyle({ border: '2px solid red' });
      inputRef.current.select();
    }
  };

  return (
    <>
      <form onSubmit={checkAnswer}>
        <p className='status'>{status}</p>
        <label style={{ margin: '0' }}>{num1} - {num2} = 
          <input
            ref={inputRef}
            type="text"
            value={answer}
            disabled={isInputDisabled}
            onChange={(e) => setAnswer(e.target.value)}
            style={inputStyle}
          />
        </label>
        <button type="submit">Submit</button>
      </form>
    </>
  );
};

export default Subtraction;
