import React from 'react';

const Footer = () => (
  <div>
    <hr></hr>
    <footer>
      © 2024 Brandon Schmitz
    </footer>
  </div>
);

export default Footer;
