import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const ExponentialGeneratingFunctions = () => {
    return (
        <div>
            <h1>Exponential Generating Functions</h1>
            <div className='group'>
                <h3>What is an Exponential Generating Function?</h3>
                <p>
                    An <span className='emph'>exponential generating function</span> (EGF) is a type of generating function that is particularly useful for sequences where the order of elements matters or when dealing with problems involving permutations or labeled structures. Unlike ordinary generating functions, the coefficients of an EGF are divided by factorials.
                </p>
                <p>
                    Given a sequence <Latex>{`$a_0, a_1, a_2, \\dots$`}</Latex>, the exponential generating function <Latex>{`$A(x)$`}</Latex> for the sequence is defined as:
                </p>
                <p className='center'>
                    <Latex>{`$A(x) = \\sum_{n=0}^{\\infty} \\frac{a_n x^n}{n!}$`}</Latex>
                </p>
                <p>
                    Here, the term <Latex>{`$\\frac{a_n}{n!}$`}</Latex> is the coefficient of <Latex>{`$x^n$`}</Latex>.
                </p>
            </div>
            <div className='group'>
                <h3>Example: Exponential Generating Function for a Simple Sequence</h3>
                <p>
                    Consider the sequence <Latex>{`$1, 1, 1, \\dots$`}</Latex>. The exponential generating function for this sequence is:
                </p>
                <p className='center'>
                    <Latex>{`$A(x) = \\sum_{n=0}^{\\infty} \\frac{x^n}{n!}$`}</Latex>
                </p>
                <p>
                    This is the Taylor series expansion of <Latex>{`$e^x$`}</Latex>, so:
                </p>
                <p className='center'>
                    <Latex>{`$A(x) = e^x$`}</Latex>
                </p>
            </div>
            <div className='group'>
                <h3>Properties of Exponential Generating Functions</h3>
                <p>
                    Exponential generating functions have several useful properties:
                </p>
                <ul>
                    <li><b>Multiplication by a constant:</b> If <Latex>{`$A(x) = \\sum_{n=0}^{\\infty} \\frac{a_n x^n}{n!}$`}</Latex>, then <Latex>{`$cA(x)$`}</Latex> is the EGF for the sequence <Latex>{`$ca_0, ca_1, ca_2, \\dots$`}</Latex>.</li>
                    <li><b>Derivative:</b> The derivative of an EGF <Latex>{`$A(x)$`}</Latex> corresponds to shifting the sequence by one index. If <Latex>{`$A(x)$`}</Latex> is the EGF for <Latex>{`$a_n$`}</Latex>, then <Latex>{`$A'(x)$`}</Latex> is the EGF for <Latex>{`$a_{n+1}$`}</Latex>.</li>
                    <li><b>Product of two EGFs:</b> If <Latex>{`$A(x)$`}</Latex> and <Latex>{`$B(x)$`}</Latex> are EGFs for sequences <Latex>{`$a_n$`}</Latex> and <Latex>{`$b_n$`}</Latex>, then their product <Latex>{`$A(x)B(x)$`}</Latex> is the EGF for the sequence formed by the convolution of <Latex>{`$a_n$`}</Latex> and <Latex>{`$b_n$`}</Latex>.</li>
                </ul>
            </div>
            <div className='group'>
                <h3>Applications of Exponential Generating Functions</h3>
                <p>
                    Exponential generating functions are particularly useful in combinatorial problems involving permutations, labeled structures, and partitions. They help simplify calculations in these contexts and are often used in solving recurrence relations and finding closed-form solutions.
                </p>
                <p><i>Example: The EGF for the Bell numbers, which count the number of partitions of a set, is <Latex>{`$B(x) = e^{e^x - 1}$`}</Latex>.</i></p>
            </div>
        </div>
    );
};

export default ExponentialGeneratingFunctions;
