import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const GeneratingFunctions = () => {
    return (
        <div>
            <h1>Generating Functions</h1>
            <div className='group'>
                <h3>Generating Functions</h3>
                <p>
                    A <span className='emph'>generating function</span> is a formal power series used to encode sequences of numbers by treating the sequence as the coefficients of a power series. It is a powerful tool in combinatorics for solving counting problems, finding closed forms of sequences, and establishing identities.
                </p>
                <p>
                    Given a sequence <Latex>{`$a_0, a_1, a_2, \\dots$`}</Latex>, the generating function <Latex>{`$A(x)$`}</Latex> for the sequence is given by:
                </p>
                <p className='center'>
                    <Latex>{`$A(x) = a_0 + a_1x + a_2x^2 + a_3x^3 + \\dots$`}</Latex>
                </p>
                <p>
                    In other words, each term in the series is a coefficient of the corresponding power of <Latex>$x$</Latex>.
                </p>
            </div>
            <div className='group'>
                <h3>Generating Function for a Simple Sequence</h3>
                <p>
                    Consider the sequence of all 1's: <Latex>{`$1, 1, 1, \\dots$`}</Latex>. The generating function for this sequence is:
                </p>
                <p className='center'>
                    <Latex>{`$A(x) = 1 + x + x^2 + x^3 + \\dots$`}</Latex>
                </p>
                <p>
                    This is a geometric series that can be summed as:
                </p>
                <p className='center'>
                    <Latex>{`$A(x) = \\frac{1}{1 - x}$`}</Latex> for <Latex>{`$|x| < 1$`}</Latex>.
                </p>
            </div>
            <div className='group'>
                <h3>Types of Generating Functions</h3>
                <p>
                    There are several types of generating functions, each useful for different kinds of sequences:
                </p>
                <ul>
                    <li><b>Ordinary generating functions</b> (OGF): The most common type, as described above.</li>
                    <li><b>Exponential generating functions</b> (EGF): Used when the sequence involves factorials, defined as <Latex>{`$$A(x) = \\sum_{n=0}^{\\infty} \\frac{a_n x^n}{n!}$$`}</Latex></li>
                    <li><b>Dirichlet generating functions</b>: Used in number theory, defined as <Latex>{`$$A(s) = \\sum_{n=1}^{\\infty} \\frac{a_n}{n^s}$$`}</Latex></li>
                    <li><b>Lambert series</b>: Another type of generating function defined as <Latex>{`$$A(x) = \\sum_{n=1}^{\\infty} \\frac{a_n x^n}{1 - x^n}$$`}</Latex></li>
                </ul>
            </div>
        </div>
    );
};

export default GeneratingFunctions;
