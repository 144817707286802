import React, { useState, useEffect } from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const Events = () => {
    return (
        <div>
            <h1>Events</h1>
            <div className='group'>
                <h3>Events</h3>
                <p>An <span class="emph">event</span> is a subset of a sample space.</p>
                <p><i>Example: A coin toss: <Latex>$E_1$</Latex> = heads, <Latex>$E_2$</Latex> = tails</i></p>
            </div>
            <div className='group'>                
                <h3>Union of Events</h3>
                    <p>
                        The <span className='emph'>union</span> of two events <Latex>$E_1$</Latex> and <Latex>$E_2$</Latex> is the event that consists of all outcomes in either event. It is denoted <Latex>$E_1 \cup E_2$</Latex>.
                    </p>
            </div>
            <div className='group'>
                <h3>Intersection of Events</h3>
                    <p>
                        The <span className='emph'>intersection</span> of two events <Latex>$E_1$</Latex> and <Latex>$E_2$</Latex> is the event that consists of all outcomes that are contained in both events. It is denoted <Latex>$E_1 \cap E_2$</Latex>.
                    </p>
            </div>
            <div className='group'>
                <h3>Complement of an Event</h3>
                <p>
                <Latex>$E'$</Latex>, the <span class="emph">complement</span> of an event <Latex>$E$</Latex>, contains all outcomes in the sample space that are not in <Latex>$E$</Latex>.
                </p>
                <p><i>Example: <Latex>$E$</Latex> = rolling a 4 on a 6-sided die <br /><Latex>$\Rightarrow E'$</Latex> = rolling a 1, 2, 3, 5, or 6</i></p>
            </div>
            
            <div className='group'>
                <h3>Mutually Exclusive Events</h3>
                <p>
                    Two events with no outcomes in common are said to be <span class="emph">mutually exclusive</span>.
                </p>
                <p>In other words, their intersection will be the empty set: <Latex>$E_1 \cap E_2 = \emptyset$</Latex></p>
            </div>
        </div>
    );
};

export default Events;