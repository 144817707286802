import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const Relations = () => {
    return (
        <div>
            <h1>Relations</h1>
            <div className='group'>
                <h3>Cartesian Products</h3>
                <p>
                    The <span className='emph'>Cartesian product</span> of two sets <Latex>$A$</Latex> and <Latex>$B$</Latex>, denoted by <Latex>$A \times B$</Latex>, is the set of all ordered pairs <Latex>$(a, b)$</Latex> where <Latex>$a \in A$</Latex> and <Latex>$b \in B$</Latex>.
                </p>
                <p><i>Example: If <Latex>{`$A = {1, 2}$`}</Latex> and <Latex>{`$B = {x, y}$`}</Latex>, then the Cartesian product <Latex>{`$A \\times B = {(1, x), (1, y), (2, x), (2, y)}$`}</Latex>.</i></p>
            </div>
            <div className='group'>
                <h3>Binary Relations</h3>
                <p>
                    A <span className='emph'>binary relation</span> from a set <Latex>$A$</Latex> to a set <Latex>$B$</Latex> is a subset of the Cartesian product <Latex>$A \times B$</Latex>. If <Latex>$(a, b) \in R$</Latex> for a relation <Latex>$R$</Latex>, we say that <Latex>$a$</Latex> is related to <Latex>$b$</Latex>.
                </p>
                <p><i>Example: For sets <Latex>{`$A = \\{1, 2, 3\\}$`}</Latex> and <Latex>{`$B = \\{x, y\\}$`}</Latex>, a possible binary relation <Latex>$R$</Latex> could be <Latex>{`$R = \\{(1, x), (2, y)\\}$`}</Latex>.</i></p>
            </div>
            <div className='group'>
                <h3>Examples of Binary Relations</h3>
                <p>
                    Consider the relation <Latex>$R$</Latex> on the set of integers <Latex>{`$\\mathbb{Z}$`}</Latex> defined by <Latex>{`$a \, R \, b$`}</Latex> if and only if <Latex>{`$a$`}</Latex> is less than <Latex>{`$b$`}</Latex>. This is a common example of a binary relation:
                </p>
                <p className='center'>
                    <Latex>{`$R = \\{(a, b) | a < b\\}$`}</Latex>
                </p>
                <p><i>Example: The pair <Latex>{`$(2, 3)$`}</Latex> is in <Latex>$R$</Latex> because <Latex>{`$2 < 3$`}</Latex>, but <Latex>{`$(3, 2)$`}</Latex> is not in <Latex>$R$</Latex> because <Latex>$3$</Latex> is not less than <Latex>$2$</Latex>.</i></p>
            </div>
        </div>
    );
};

export default Relations;
