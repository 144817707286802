import React, { useState } from 'react';
import '../styles/DataStructs.css';

const BoyerMoorePatternMatching = () => {
  const [text, setText] = useState('');
  const [pattern, setPattern] = useState('');
  const [matches, setMatches] = useState([]);
  const [iterations, setIterations] = useState([]);
  const procedure = `
  ur mom gay
  lol
  haha`;

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handlePatternChange = (e) => {
    setPattern(e.target.value);
  };

  const badCharacterHeuristic = (str, size) => {
    const badChar = Array(256).fill(-1);
    for (let i = 0; i < size; i++) {
      badChar[str.charCodeAt(i)] = i;
    }
    return badChar;
  };

  const boyerMooreSearch = (txt, pat) => {
    const m = pat.length;
    const n = txt.length;
    const badChar = badCharacterHeuristic(pat, m);
    const result = [];
    const iter = [];

    let s = 0;
    while (s <= (n - m)) {
      let j = m - 1;
      let iteration = Array(n).fill(null);

      for (let k = 0; k < m; k++) {
        iteration[s + k] = 'checked';
      }

      while (j >= 0 && pat[j] === txt[s + j]) {
        iteration[s + j] = 'matched';
        j--;
      }

      if (j < 0) {
        result.push(s);
        iter.push({ index: s, iteration: [...iteration] });
        s += (s + m < n) ? m - badChar[txt.charCodeAt(s + m)] : 1;
      } else {
        iteration[s + j] = 'not-matched';
        iter.push({ index: s, iteration: [...iteration] });
        s += Math.max(1, j - badChar[txt.charCodeAt(s + j)]);
      }
    }

    setIterations(iter);
    return result;
  };

  const handleSearch = () => {
    const result = boyerMooreSearch(text, pattern);
    setMatches(result);
  };

  return (
    <div>
      <h1>Boyer-Moore Pattern Matching Visualizer</h1>
      <div className='controls-container'>
        <div className="controls">
          <input
            type="text"
            value={text}
            onChange={handleTextChange}
            placeholder="Enter text"
          />
          <input
            type="text"
            value={pattern}
            onChange={handlePatternChange}
            placeholder="Enter pattern"
          />
          <button onClick={handleSearch}>Search</button>
        </div>
      </div>
      <div className="algorithm-visualization">
        <div className="grid-row">
          {text.split('').map((_, index) => (
            <span key={index} className="index-label grid-cell">
              {index}
            </span>
          ))}
        </div>
        <div className="grid-row">
          {text.split('').map((char, index) => (
            <span key={index} className="grid-cell grid-header">
              {char}
            </span>
          ))}
        </div>
        {iterations.map((iteration, iterIndex) => (
          <div key={iterIndex} className="grid-row">
            {Array(text.length).fill(null).map((_, index) => (
              <span key={index} className={`grid-cell ${iteration.iteration[index] || ''}`}>
                {index >= iteration.index && index < iteration.index + pattern.length ? pattern[index - iteration.index] : ''}
              </span>
            ))}
          </div>
        ))}
      </div>
      <div className="matches">
        {matches.length > 0 ? (
          <p>Pattern found at indices: {matches.join(', ')}</p>
        ) : (
          <p>No matches found</p>
        )}
      </div>
      <pre>{`
      m = length of pattern
      n = length of text
      i := 0 ← start index
      while (i <= n - m):
        j := m - 1
        while (j >= 0 and text[i+j] = pattern[j])
            j = j - 1
      `}</pre>
      <div>
        <p>m = length of pattern</p>
        <p>n = length of text</p>
      </div>
    </div>
  );
};

export default BoyerMoorePatternMatching;
