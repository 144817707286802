import React from 'react';
import Addition from '../subtopics/Addition';
import ArrayList from '../subtopics/ArrayList';
import BinarySearchTree from '../subtopics/BinarySearchTree';
import BoyerMoorePatternMatching from '../subtopics/BoyerMoore';
import BruteForcePatternMatching from '../subtopics/BruteForcePatternMatching';
import BubbleSort from '../subtopics/BubbleSort';
import CircularLinkedList from '../subtopics/CircularLinkedList';
import ClefsNotes from '../subtopics/ClefsNotes';
import CocktailShakerSort from '../subtopics/CocktailShakerSort';
import Combinations from '../subtopics/Combinations';
import CylindricalCoordinates from '../subtopics/CylindricalCoordinates';
import Deque from '../subtopics/Deque';
import Derangements from '../subtopics/Derangements';
import Division from '../subtopics/Division';
import DivisionAlgorithm from '../subtopics/DivisionAlgorithm';
import DoublyLinkedList from '../subtopics/DoublyLinkedList';
import Events from '../subtopics/Events';
import ExponentialGeneratingFunctions from '../subtopics/ExponentialGeneratingFunction';
import FunctionComposition from '../subtopics/FunctionComposition';
import FundamentalTheoremOfArithmetic from '../subtopics/FundamentalTheoremOfArithmetic';
import GeneratingFunctions from '../subtopics/GeneratingFunctions';
import GreatestCommonDivisor from '../subtopics/GreatestCommonDivisor';
import InclusionExclusion from '../subtopics/InclusionExclusion';
import InsertionSort from '../subtopics/InsertionSort';
import InverseFunctions from '../subtopics/InverseFunctions';
import KMPSearch from '../subtopics/KMP';
import LineEquations from '../subtopics/LineEquations';
import LinkedList from '../subtopics/LinkedList';
import MajorKeys from '../subtopics/MajorKeys';
import MathematicalInduction from '../subtopics/MathematicalInduction';
import MinorKeys from '../subtopics/MinorKeys';
import Multiplication from '../subtopics/Multiplications';
import OntoOneToOne from '../subtopics/OntoOneToOne';
import PartitionsOfIntegers from '../subtopics/PartitionsOfIntegers';
import Permutations from '../subtopics/Permutations';
import PlaneEquations from '../subtopics/PlaneEquations';
import QuadricSurfaces from '../subtopics/QuadricSurfaces';
import Queue from '../subtopics/Queue';
import RandomExperiments from '../subtopics/RandomExperiments';
import RecursiveDefinitions from '../subtopics/RecursiveDefinitions';
import RookPolynomials from '../subtopics/RookPolynomials';
import RRFirstOrderLinear from '../subtopics/RRFirstOrderLinear';
import Relations from '../subtopics/Relations';
import Stack from '../subtopics/Stack';
import Subtraction from '../subtopics/Subtraction';
import SumProdRule from '../subtopics/SumProdRule';
import Vectors2D from '../subtopics/Vectors2D';
import Vectors3D from '../subtopics/Vectors3D';
import RRSecondOrderHomogeneous from '../subtopics/RRSecondOrderHomogeneous';
import RRNonhomogeneous from '../subtopics/RRNonhomogeneous';
import Graphs from '../subtopics/Graphs';

// Define a general subtopic component
const Subtopic = ({ name, children }) => (
  <div>
    <h1>{name}</h1>
    <p>{children}</p>
  </div>
);

// Map subtopic names to components with dynamic headers and specific content
export const subtopicComponents = {  
  "2D Vectors": Vectors2D,
  "3D Vectors": Vectors3D,
  "Addition": Addition,
  "ArrayList": ArrayList,
  // "Binary Search Tree": BinarySearchTree,
  "Boyer-Moore": BoyerMoorePatternMatching,
  "Brute Force Pattern Matching": BruteForcePatternMatching,
  "Bubble Sort": BubbleSort,
  "Circularly LinkedList": CircularLinkedList,
  "Clefs & Notes": ClefsNotes,
  "Cocktail Shaker Sort": CocktailShakerSort,
  "Combinations": Combinations,
  "Cylindrical Coordinates": CylindricalCoordinates,
  "Derangements": Derangements,
  "Deque": Deque,  
  "Division": Division,
  "Division Algorithm": DivisionAlgorithm,
  "Doubly LinkedList": DoublyLinkedList,
  "Events": Events,
  "Exponential Generating Functions": ExponentialGeneratingFunctions,
  "Function Composition": FunctionComposition,
  "Functions: Onto/One-to-One": OntoOneToOne,
  "Fundamental Theorem of Arithmetic": FundamentalTheoremOfArithmetic,
  "Graphs": Graphs,
  "Generating Functions": GeneratingFunctions,
  "Greatest Common Divisor": GreatestCommonDivisor,
  "Insertion Sort": InsertionSort,
  "Inverse Functions": InverseFunctions,
  "KMP": KMPSearch,
  "Line Equations": LineEquations,
  "Major Keys": MajorKeys,
  "Mathematical Induction": MathematicalInduction,
  "Minor Keys": MinorKeys,
  "Multiplication": Multiplication,
  "Partitions of Integers": PartitionsOfIntegers,
  "Permutations": Permutations,
  "Plane Equations": PlaneEquations,
  "Principle of Inclusion and Exclusion": InclusionExclusion,
  "Recurrence Relations: First-Order Linear": RRFirstOrderLinear,
  "Recurrence Relations: Nonhomogeneous": RRNonhomogeneous,
  "Recurrence Relations: Second-Order Homogeneous": RRSecondOrderHomogeneous,
  "Quadric Surfaces": QuadricSurfaces,
  "Queue": Queue,
  "Random Experiments": RandomExperiments,
  "Recursive Definitions": RecursiveDefinitions,
  "Relations": Relations,
  "Rook Polynomials": RookPolynomials,
  "Singly LinkedList": LinkedList,
  "Spherical Coordinates": () => <Subtopic name="Spherical Coordinates">Spherical coordinates can be thought of as an extension of polar coordinates from two dimensions to three dimensions.</Subtopic>,
  "Stack": Stack,
  "Subtraction": Subtraction,
  "Sum & Product Rules": SumProdRule
};

// Define the topics with their respective subtopics
export const topics = {
  "Arithmetic": [
    "Addition", "Subtraction", "Multiplication", "Division"
  ],
  "Calculus": [
    "Derivatives: Constant Rule", "Derivatives: Sum Rule", "Derivatives: Product Rule", "Derivatives: Quotient Rule", "Derivatives: Chain Rule", "L'Hopital's Rule"
  ],
  "Chemistry": [

  ],
  "Combinatorics": [
    "Sum & Product Rules", "Permutations", "Combinations",
    "Mathematical Induction", "Recursive Definitions", "Division Algorithm", "Greatest Common Divisor", "Fundamental Theorem of Arithmetic",
    "Relations", "Functions: Onto/One-to-One", "Function Composition", "Inverse Functions",
    "Principle of Inclusion and Exclusion", "Derangements", "Rook Polynomials",
    "Generating Functions", "Partitions of Integers", "Exponential Generating Function",
    "Recurrence Relations: First-Order Linear", "Recurrence Relations: Second-Order Homogeneous", "Recurrence Relations: Nonhomogeneous",
    "Graphs", "Subgraphs", "Graph Complements", "Graph Isomorphism", "Vertex Degree", "Planar Graphs", "Hamilton Paths/Cycles", "Graph Coloring",
    "Trees", "Rooted Trees", "Trees: Sorting", "Weighted Trees",
    "Bread-First Search", "Depth-First Search", "Dijkstra's Shortest Path", "Prim's MST", "Kruskal's MST"
  ],
  "Computer Architecture": [
    "Bit Logic", "Integer Datatypes"
  ],
  "Computer Vision": [
    "2D Translation", "2D Euclidean Transformation", "2D Scaling", "2D Affine Transformation", "2D Projection", "3D Translation", "3D Euclidean Transformation", "3D Scaling", "3D Affine Transforamtion", "3D Projection", "Orthography", "Lens Distortion"
  ],
  "Data Structures & Algorithms": [
    "Array", "ArrayList", "Singly LinkedList", "Doubly LinkedList", "Circularly LinkedList",
    "Stack", "Queue", "Deque",
    "Binary Search Tree", "Heap", "AVL", "2-4 Tree", "SplayTree", "SkipList",
    "Hashmap (Chaining)", "HashMap (Probing)",
    "Bubble Sort", "Cocktail Shaker Sort", "Insertion Sort", "Selection Sort", "QuickSort", "Quick Select", "MergeSort", "LSD Radix Sort", "HeapSort",
    "Brute Force Pattern Matching", "Boyer-Moore", "KMP", "Rabin-Karp",
    "Breadth-First Search", "Depth-First Search", "Dijkstra's Shortest Path", "A* Search", "Prim's MST", "Kruskal's MST",
    "Longest Common Subsequence"
  ],
  "Discrete Math": [
    "Sets: Complement", "Sets: Union/Intersect", "DeMorgan's Laws", "Power Sets", "Venn Diagrams"
  ],
  "Formal Language Theory": [
    "Regular Languages", "DFAs", "NFAs", "Regular Expressions", "GNFAs",
    "Context-Free Languages", "Chomsky Normal Form", "Pushdown Automata", "Pumping Lemma", "DPDAs", "DCFGs", "LR(k) Grammars",
    "Turing Machines", "Hilbert's 10th Problem",
    "Decidability", "Diagonalization Method",
    "Halting Problem", "Computation History", "LBAs", "Post Correspondence Theorem", "Computable Functions", "Mapping Reducibility",
    "Recursion Theorem", "Decidability of Logical Theories", "Turing Redicibility", "Minimal Descriptions", "Incompressible Strings",
    "Time Complexity", "Class P", "Class NP", "P vs NP", "NP-Completeness", "Cook-Levin Theorem",
    "Space Complexity", "Savitch's Theorem", "PSPACE", "PSPACE-Completeness", "Classes L & NL", "NL-Completeness",
    "Hierarchy Theorems", "EXSPACE-Completeness", "Relativization", "Circuit Complexity",
    "Approximation Algorithms", "Probabilistic Algorithms", "Alternation", "Interactive Proof Systems", "One-Way Functions"
  ],
  "Multivariable Calculus": [
    "2D Vectors", "3D Vectors", 
    "Line Equations", "Plane Equations", "Quadric Surfaces", "Ellipsoids", "Cylindrical Coordinates", "Spherical Coordinates",
    "Vector-Valued Functions", "Arc Length", "Curvature", "Motion In Space",
    "Functions of Two Variables", "Level Curve", "Vertical Trace", "Disk",
    "Multivariable Functions", "Multivariate Limits/Continuity", "Partial Derivatives", "Tangent Planes", "Partial Derivaties: Chain Rule", "Direction Derivatives & Gradient", "Partial Derivatives: Critical Points", "Lagrange Multipliers",
    "Double Integrals over Rectangular Regions", "Double Integrals over General Regions", "Triple Integrals", "Triple Integrals: Cylindrical", "Triple Integrals: Spherical", "Integration: Center of Mass", "Integration: Moment of Inertia",
    "Vector Fields", "Line Integrals", "Conservative Vector Fields", "Green's Theorem", "Divergence/Curl", "Surface Integrals", "Stokes' Theorem", "Divergence Theorem"
  ],
  "Music": [
    "Simple Meter", "Compound Meter", "Complex Meter", "Clefs & Notes", "Major Keys", "Minor Keys"
  ],
  "Probability & Statistics": [
    "Random Experiments", "Events", "Conditional Probability",
    "Probability: Complement", "Probability: Independence", "Bayes' Theorem",
    "Discrete Probability Distributions", "Probability Mass Functions", "Discrete Distributions: CDFs", "Discrete Uniform Distribution", "Binomial Distribution", "Geometric & Negative Binomial Distributions", "Hypergeometric Distribution", "Poisson Distribution",
    "Continuous Probability Distributions", "Probability Density Functions", "Continuous Distributions: CDFs", "Continuous Uniform Distribution", "Normal Distribution", "Normal Approximations of Binomial and Poisson", "Exponential Distribution",
    "Joint Probability Distributions", "Conditional Probability Distributions", "Covariance & Correlation",
    "Stem-and-Leaf Diagrams", "Histograms", "Box Plots", "Scatter Diagrams", "Probability Plots"
  ]
};
