import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const RookPolynomials = () => {
    return (
        <div>
            <h1>Rook Polynomials</h1>
            <div className='group'>
                <h3>Introduction to Rook Polynomials</h3>
                <p>
                    A <span className='emph'>rook polynomial</span> is a polynomial that counts the number of ways to place non-attacking rooks on a chessboard (or more generally, on a grid). In this context, a "non-attacking" arrangement means that no two rooks share the same row or column.
                </p>
                <p>
                    If we consider an <Latex>$m \times n$</Latex> grid, where <Latex>$r_k$</Latex> represents the number of ways to place <Latex>$k$</Latex> non-attacking rooks on the grid, the rook polynomial <Latex>$R(C, x)$</Latex> is defined as:
                </p>
                <p className='center'>
                    <Latex>{`$R(C, x) = r_0 + r_1 x + r_2 x^2 + \\dots + r_n x^n$`}</Latex>
                </p>
                <p>
                    Here, <Latex>$C$</Latex> represents the grid, and <Latex>$r_0$</Latex> is always 1, representing the empty grid.
                </p>
            </div>
            <div className='group'>
                <h3>Simple Case: 2x2 Grid</h3>
                <p>
                    Consider a <Latex>$2 \times 2$</Latex> grid. The rook polynomial for this grid can be calculated as follows:
                </p>
                <ul>
                    <li><Latex>{`$r_0 = 1$`}</Latex> (no rooks)</li>
                    <li><Latex>{`$r_1 = 4$`}</Latex> (4 ways to place 1 rook)</li>
                    <li><Latex>{`$r_2 = 2$`}</Latex> (2 ways to place 2 non-attacking rooks)</li>
                </ul>
                <p>
                    The rook polynomial is therefore:
                </p>
                <p className='center'>
                    <Latex>{`$R(C, x) = 1 + 4x + 2x^2$`}</Latex>
                </p>
            </div>
            <div className='group'>
                <h3>General Case: m x n Grid</h3>
                <p>
                    For a general <Latex>$m \times n$</Latex> grid, the calculation of rook polynomials involves more complex combinatorial counting. However, the general idea remains the same: each coefficient of <Latex>$x^k$</Latex> represents the number of ways to place <Latex>$k$</Latex> non-attacking rooks on the grid.
                </p>
                <p>
                    <i>For example, on a <Latex>$3 \times 3$</Latex> grid:</i>
                </p>
                <p className='center'>
                    <Latex>{`$R(C, x) = 1 + 9x + 18x^2 + 6x^3$`}</Latex>
                </p>
            </div>
        </div>
    );
};

export default RookPolynomials;
