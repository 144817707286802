import React from 'react';
import { useNavigate } from 'react-router-dom';
import { topics } from '../utils/subtopics';  // Adjust the path as necessary
import Header from '../Header';
import Footer from '../Footer';
import '../styles/Home.css'

const Topics = () => {
  const navigate = useNavigate();

  const handleNavigate = (topic) => {
    navigate(`/learn?topic=${encodeURIComponent(topic)}`);
  };

  const mathTopics = ["Arithmetic", "Algebra", "Geometry", "Probability & Statistics", "Discrete Math", "Calculus", "Multivariable Calculus", "Combinatorics"];
  const csTopics = ["Data Structures & Algorithms", "Computer Architecture", "Formal Language Theory", "Computer Vision"];
  const scienceTopics = ["Chemistry"]
  const humanitiesTopics = ["Music"]

  return (
    <>
      <Header />
      <br /><br />
      <div className='group-container'>
        <br /><br />
        <div className='group'>
          <h2>Mathematics</h2>
          <div className='card-collection'>
            {mathTopics.map((topic, index) => (
              <button className='card' key={index} onClick={() => handleNavigate(topic)}>
                {topic}
              </button>
            ))}
          </div>
        </div>
        
        <div className='group'>
          <h2>Computer Science</h2>
          <div className='card-collection'>
            {csTopics.map((topic, index) => (
              <button className='card' key={index} onClick={() => handleNavigate(topic)}>
                {topic}
              </button>
            ))}
          </div>
        </div>
        
        <div className='group'>
          <h2>Science</h2>
          <div className='card-collection'>
            {scienceTopics.map((topic, index) => (
              <button className='card' key={index} onClick={() => handleNavigate(topic)}>
                {topic}
              </button>
            ))}
          </div>
        </div>
        
        <div className='group'>
          <h2>Humanities</h2>
          <div className='card-collection'>
            {humanitiesTopics.map((topic, index) => (
              <button className='card' key={index} onClick={() => handleNavigate(topic)}>
                {topic}
              </button>
            ))}
          </div>
        </div>

        {/* <div className='group'>
          <h2>Study Topics</h2>
          <div className='card-collection'>
            {Object.keys(topics).map(topic => (
              <button className='card' key={topic} onClick={() => handleNavigate(topic)}>
                {topic}
              </button>
            ))}
          </div>
        </div> */}
                
        <br /><br />
      </div>
      <Footer />
    </>
  );
};

export default Topics;
