import React, { useState, useEffect, useRef } from 'react';

const getRandomNumbers = (repeat) => {
  let num1 = Math.floor(Math.random() * 10);
  let num2 = Math.floor(Math.random() * 10);
  if (repeat && num1 === repeat[0] && num2 === repeat[1]) {
    return getRandomNumbers(repeat); // Ensure new numbers if the same as previous
  }
  return [num1, num2];
};

const Addition = () => {
  const [[num1, num2], setNumbers] = useState(getRandomNumbers());
  const [answer, setAnswer] = useState('');
  const [status, setStatus] = useState('');
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [inputStyle, setInputStyle] = useState({ })
  const inputRef = useRef(null);

  useEffect(() => {
    setStatus('Practice addition:');
    setInputStyle({ border: '1px solid rgb(163, 163, 163)' });
    if (inputRef.current) {
      inputRef.current.disabled = false;     
      inputRef.current.focus();
    }
  }, [num1, num2]);

  const checkAnswer = (e) => {
    e.preventDefault();
    if (parseInt(answer) === num1 + num2) {
      setStatus('Good job!');
      setInputStyle({ border: '2px solid green' });
      setIsInputDisabled(true);
      setTimeout(() => {
        setNumbers(getRandomNumbers([num1, num2]));
        setAnswer('');
        setIsInputDisabled(false);
      }, 750);
    } else {
      setStatus('Try again!');
      setInputStyle({ border: '2px solid red' });
      inputRef.current.select();
    }
  };

  return (
    <>      
      <form onSubmit={checkAnswer}>
        <p className='status'>{status}</p>
        <label>
          {num1} + {num2} = &nbsp;
          <input
            ref={inputRef}
            type="text"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            disabled={isInputDisabled}
            style={inputStyle}
          />
        </label>
        &nbsp;
        <button type="submit" disabled={isInputDisabled}>Submit</button>
      </form>
    </>
  );
};

export default Addition;
