import React, { useState, useEffect, useRef } from 'react';

const getRandomNumbers = (repeat) => {
  let num1 = Math.floor(Math.random() * 11);
  let num2 = Math.floor(Math.random() * 11);
  if (repeat && num1 === repeat[0] && num2 === repeat[1]) return getRandomNumbers(repeat); // regenerate numbers if they are the same  
  return [num1, num2];
};

const Multiplication = () => {
  const [[num1, num2], setNumbers] = useState(getRandomNumbers());
  const [answer, setAnswer] = useState('');
  const [status, setStatus] = useState('');
  const [isInputDisabled, setIsInputDisabled] = useState(false); // State to control input disabled/enabled
  const [inputStyle, setInputStyle] = useState({ })
  const inputRef = useRef(null); // Reference to the input element

  useEffect(() => {
    setStatus('Practice multiplication:'); // Reset status message when numbers change
    setInputStyle({ border: '2px solid black' });
    if (inputRef.current) {
      inputRef.current.disabled = false; // Re-enable the input when new numbers are set      
      inputRef.current.focus();
    }
  }, [num1, num2]);

  const checkAnswer = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    if (answer.trim() === '') setStatus('Enter an answer');
    else if (parseInt(answer) === num1 * num2) {
      setStatus('Good job!');
      setInputStyle({ border: '2px solid green' });
      setIsInputDisabled(true); // Disable input to hide cursor
      setTimeout(() => {
        setNumbers(getRandomNumbers([num1, num2]));
        setAnswer(''); // Clear answer for the next question
        setIsInputDisabled(false); // Re-enable the input for the next question
      }, 750);
    } else {
      if (parseInt(answer) === num1 + num2) setStatus('Multiplication, not addition!');    
      else setStatus('Try again!');
      setInputStyle({ border: '2px solid red' });
      inputRef.current.select();
    }
  };

  return (
    <>      
      <form onSubmit={checkAnswer}>
        <p className='status'>{status}</p>
        <label>
          <span className='question'>{num1} × {num2} = </span>
          <input
            ref={inputRef}
            type="text"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            disabled={isInputDisabled} // Control the disabled state based on isInputDisabled
            style={inputStyle}
          />
        </label>
        <button type="submit" disabled={isInputDisabled}>Submit</button>
      </form>
    </>
  );
};

export default Multiplication;