import React, { useState, useEffect } from 'react';
import '../styles/DataStructs.css';

const getRandomDeque = () => {
  const length = Math.floor(Math.random() * 10) + 1;
  const array = Array.from({ length }, () => Math.floor(Math.random() * 100));
  return array;
};

const Deque = () => {
  const [deque, setDeque] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    setDeque(getRandomDeque());
  }, []);

  const handleEnqueueFront = () => {
    setDeque([inputValue, ...deque]);
    setInputValue('');
  };

  const handleEnqueueBack = () => {
    setDeque([...deque, inputValue]);
    setInputValue('');
  };

  const handleDequeueFront = () => {
    if (deque.length > 0) {
      setDeque(deque.slice(1));
    }
  };

  const handleDequeueBack = () => {
    if (deque.length > 0) {
      setDeque(deque.slice(0, -1));
    }
  };

  const handleItemClick = (index) => {
    setSelectedIndex(index === selectedIndex ? null : index);
  };

  return (
    <div>
      <h1>Deque Visualizer</h1>
      <div className='controls-container'>
        <div className="controls">
          <div>
            <input
              type="number"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="value"
            />
            <button onClick={handleEnqueueFront}>Enqueue Front</button>
            <button onClick={handleEnqueueBack}>Enqueue Back</button>
          </div>
        </div>
        <div className="controls">
          <button onClick={handleDequeueFront}>Dequeue Front</button>
          <button onClick={handleDequeueBack}>Dequeue Back</button>
        </div>
      </div>
      <div className="data-struct-visualization">
        {deque.map((item, index) => (
          <div key={index} className="linkedlist-node">
            <div 
                className={`array-item ${index === selectedIndex ? 'selected' : ''}`}
                onClick={() => handleItemClick(index)}>
                {item ? item : '-'}
                <div className="index-label">{index}</div>
            </div>
            {index < deque.length - 1 && <div className="node-pointer"></div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Deque;
