import React, { useState, useEffect } from 'react';
import '../styles/DataStructs.css';

const getRandomStack = () => {
  const length = Math.floor(Math.random() * 10) + 1;
  const array = Array.from({ length }, () => Math.floor(Math.random() * 100));
  return array;
};

const Stack = () => {
  const [stack, setStack] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    setStack(getRandomStack());
  }, []);

  const handlePush = () => {
    setStack([...stack, inputValue]);
    setInputValue('');
  };

  const handlePop = () => {
    if (stack.length > 0) {
      setStack(stack.slice(0, -1));
    }
  };

  const handleItemClick = (index) => {
    setSelectedIndex(index === selectedIndex ? null : index);
  };

  return (
    <div>
      <h1>Stack Visualizer</h1>
      <div className='controls-container'>
        <div className="controls">
          <div>
            <input
              type="number"
              className='left-input'
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="value"
            />
            <button onClick={handlePush}>Push</button>
          </div>
          <button onClick={handlePop}>Pop</button>
        </div>
      <div className="data-struct-visualization stack round">
        {stack.map((item, index) => (
          <div key={index} className="stack-node">
            <div 
                className={`array-item ${index === selectedIndex ? 'selected' : ''}`}
                onClick={() => handleItemClick(index)}>
                {item ? item : '-'}
            </div>
            <div className="index-label">{index}</div>
          </div>
        )).reverse()}
      </div>
      </div>
    </div>
  );
};

export default Stack;
