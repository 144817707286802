import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const FunctionComposition = () => {
    return (
        <div>
            <h1>Function Composition</h1>
            <div className='group'>
                <h3>Composition of Functions</h3>
                <p>
                    The <span className='emph'>composition</span> of two functions <Latex>$f$</Latex> and <Latex>$g$</Latex> is a function formed by applying one function to the result of another. If <Latex>$f: A \rightarrow B$</Latex> and <Latex>$g: B \rightarrow C$</Latex>, the composition of <Latex>$f$</Latex> and <Latex>$g$</Latex>, denoted by <Latex>{`$g \\circ f$`}</Latex>, is a function from <Latex>$A$</Latex> to <Latex>$C$</Latex> defined by:
                </p>
                <p className='center'>
                    <Latex>{`$(g \\circ f)(x) = g(f(x))$`}</Latex>
                </p>
                <p><i>Example: Let <Latex>$f(x) = 2x + 3$</Latex> and <Latex>$g(x) = x^2$</Latex>. Then, the composition <Latex>{`$(g \\circ f)(x)$`}</Latex> is given by <Latex>{`$g(f(x)) = (2x + 3)^2$`}</Latex>.</i></p>
            </div>
            <div className='group'>
                <h3>Associativity of Function Composition</h3>
                <p>
                    The composition of functions is <span className='emph'>associative</span>. If <Latex>$f: A \rightarrow B$</Latex>, <Latex>$g: B \rightarrow C$</Latex>, and <Latex>$h: C \rightarrow D$</Latex>, then:
                </p>
                <p className='center'>
                    <Latex>{`$h\\circ (g\\circ f) = (h\\circ g)\\circ f$`}</Latex>
                </p>
                <p>
                    This means that the order in which you compose the functions does not matter; the result will be the same.
                </p>
                <p><i>Example: Let <Latex>$f(x) = x + 1$</Latex>, <Latex>$g(x) = 2x$</Latex>, and <Latex>$h(x) = x^2$</Latex>. Then:</i></p>
                <p className='center'>
                    <Latex>{`$h\\circ (g\\circ f)(x) = ((2(x + 1))^2)$`}</Latex> and <Latex>{`$((h\\circ g)\\circ f)(x) = ((2x)^2 + 2x + 1)$`}</Latex>.
                </p>
            </div>
            <div className='group'>
                <h3>Identity Function</h3>
                <p>
                    For any set <Latex>$A$</Latex>, the <span className='emph'>identity function</span> <Latex>$id_A: A \rightarrow A$</Latex> is defined by <Latex>{`$id_A(x) = x$`}</Latex> for all <Latex>$x \in A$</Latex>.
                </p>
                <p>
                    For any function <Latex>$f: A \rightarrow B$</Latex>, the composition with the identity function satisfies:
                </p>
                <p className='center'>
                    <Latex>{`$f\\circ id_A = f$`}</Latex> and <Latex>{`$id_B\\circ f = f$`}</Latex>.
                </p>
                <p>
                    This means that composing a function with an identity function does not change the original function.
                </p>
                <p><i>Example: Let <Latex>$f(x) = 2x + 3$</Latex>. Then <Latex>{`$f\\circ id_A(x) = 2x + 3$`}</Latex> and <Latex>{`$id_B\\circ f(x) = 2x + 3$`}</Latex>.</i></p>
            </div>
        </div>
    );
};

export default FunctionComposition;
