import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const RRSecondOrderHomogeneous = () => {
    return (
        <div>
            <h1>Recurrence Relations: Second-Order Homogeneous</h1>
            <div className='group'>
                <h3>Second-Order Homogeneous Recurrence Relations</h3>
                <p>
                    In a <span className='emph'>second-order homogeneous recurrence relation</span>, each term of a sequence is defined as a linear combination of the two preceding terms. The term <span class="emph">homogeneous</span> means that the recurrence relation does not have any additional function or constant term added to it.
                </p>
                <p>
                    The general form of a second-order homogeneous recurrence relation is:
                </p>
                <p className='center'>
                    <Latex>{`$a_n = c_1 a_{n-1} + c_2 a_{n-2}$`}</Latex>
                </p>
                <p>
                    Here, <Latex>{`$c_1$`}</Latex> and <Latex>{`$c_2$`}</Latex> are constants, and the relation depends on the two previous terms, <Latex>{`$a_{n-1}$`}</Latex> and <Latex>{`$a_{n-2}$`}</Latex>.
                </p>
            </div>
            <div className='group'>
                <h3>Characteristic Equation</h3>
                <p>
                    To solve a second-order homogeneous recurrence relation, we first find its <span className='emph'>characteristic equation</span>. The characteristic equation is derived by assuming a solution of the form <Latex>{`$a_n = r^n$`}</Latex> and substituting it into the recurrence relation.
                </p>
                <p>
                    Substituting <Latex>{`$a_n = r^n$`}</Latex> into the recurrence relation <Latex>{`$a_n = c_1 a_{n-1} + c_2 a_{n-2}$`}</Latex> gives:
                </p>
                <p className='center'>
                    <Latex>{`$r^n = c_1 r^{n-1} + c_2 r^{n-2}$`}</Latex>
                </p>
                <p>
                    Dividing through by <Latex>{`$r^{n-2}$`}</Latex> results in the characteristic equation:
                </p>
                <p className='center'>
                    <Latex>{`$r^2 - c_1 r - c_2 = 0$`}</Latex>
                </p>
                <p>
                    This quadratic equation can be solved using the quadratic formula to find the roots, <Latex>{`$r_1$`}</Latex> and <Latex>{`$r_2$`}</Latex>.
                </p>
            </div>
            <div className='group'>
                <h3>General Solution</h3>
                <p>
                    The general solution of the second-order homogeneous recurrence relation depends on the nature of the roots of the characteristic equation:
                </p>
                <ul>
                    <li><b>Distinct Real Roots:</b> If the characteristic equation has two distinct real roots, <Latex>{`$r_1$`}</Latex> and <Latex>{`$r_2$`}</Latex>, the general solution is:
                        <p className='center'>
                            <Latex>{`$a_n = A r_1^n + B r_2^n$`}</Latex>
                        </p>
                        where <Latex>{`$A$`}</Latex> and <Latex>{`$B$`}</Latex> are constants determined by initial conditions.
                    </li>
                    <li><b>Repeated Root:</b> If the characteristic equation has a repeated root, <Latex>{`$r$`}</Latex>, the general solution is:
                        <p className='center'>
                            <Latex>{`$a_n = (A + Bn) r^n$`}</Latex>
                        </p>
                        where <Latex>{`$A$`}</Latex> and <Latex>{`$B$`}</Latex> are constants determined by initial conditions.
                    </li>
                    <li><b>Complex Roots:</b> If the characteristic equation has complex roots, <Latex>{`$r_1 = \\alpha + \\beta i$`}</Latex> and <Latex>{`$r_2 = \\alpha - \\beta i$`}</Latex>, the general solution is:
                        <p className='center'>
                            <Latex>{`$a_n = r^n (A \\cos(n\\theta) + B \\sin(n\\theta))$`}</Latex>
                        </p>
                        where <Latex>{`$r = \\sqrt{\\alpha^2 + \\beta^2}$`}</Latex> and <Latex>{`$\\theta = \\arctan(\\beta/\\alpha)$`}</Latex>.
                    </li>
                </ul>
            </div>
            <div className='group'>
                <h3>Example: Solving a Second-Order Homogeneous Recurrence Relation</h3>
                <p>
                    Consider the recurrence relation:
                </p>
                <p className='center'>
                    <Latex>{`$a_n = 5a_{n-1} - 6a_{n-2}$`}</Latex>
                </p>
                <p>
                    The characteristic equation is:
                </p>
                <p className='center'>
                    <Latex>{`$r^2 - 5r + 6 = 0$`}</Latex>
                </p>
                <p>
                    Solving this quadratic equation, we find the roots <Latex>{`$r_1 = 2$`}</Latex> and <Latex>{`$r_2 = 3$`}</Latex>. Thus, the general solution is:
                </p>
                <p className='center'>
                    <Latex>{`$a_n = A 2^n + B 3^n$`}</Latex>
                </p>
                <p>
                    The constants <Latex>{`$A$`}</Latex> and <Latex>{`$B$`}</Latex> can be determined using initial conditions.
                </p>
            </div>
        </div>
    );
};

export default RRSecondOrderHomogeneous;
