import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const RecursiveDefinitions = () => {
    return (
        <div>
            <h1>Recursive Definitions</h1>

            <div className='group'>
                <h3>Recursive Definition</h3>
                <p>
                    A <span className='emph'>recursive definition</span> defines an object in terms of simpler instances of itself. In mathematics and computer science, recursive definitions are often used to define sequences, functions, and structures.
                </p>
                <p>
                    Recursive definitions typically involve two main components:
                </p>
                <ul>
                    <li>
                        <b>Base Case</b>: The simplest instance of the object being defined, which terminates the recursion.
                    </li>
                    <li>
                        <b>Recursive Step</b>: The rule that defines how to construct the object from a simpler version of itself.
                    </li>
                </ul>
                <p>
                    Together, these components allow complex objects or sequences to be built systematically from simpler ones.
                </p>
            </div>

            <div className='group'>
                <h3>Examples of Recursive Definitions</h3>

                <h4>Factorial Function</h4>
                <p>
                    The factorial function, denoted by <Latex>$n!$</Latex>, is defined recursively as follows:
                </p>
                <ul>
                    <li>
                        <b>Base Case:</b> <Latex>$0! = 1$</Latex>
                    </li>
                    <li>
                        <b>Recursive Step:</b> <Latex>$n! = n \times (n-1)!$</Latex> for <Latex>$n \geq 1$</Latex>
                    </li>
                </ul>
                <p>
                    <i>Example: To compute <Latex>$3!$</Latex>, we have:</i>
                    <ul>
                        <li><Latex>$3! = 3 \times 2!$</Latex></li>
                        <li><Latex>$2! = 2 \times 1!$</Latex></li>
                        <li><Latex>$1! = 1 \times 0!$</Latex></li>
                        <li><Latex>$0! = 1$</Latex></li>
                    </ul>
                    <p><i>Thus, <Latex>$3! = 3 \times 2 \times 1 = 6$</Latex>.</i></p>
                </p>

                <h4>Fibonacci Sequence</h4>
                <p>
                    The Fibonacci sequence is defined recursively as follows:
                </p>
                <ul>
                    <li>
                        <b>Base Case:</b> <Latex>$F(0) = 0$</Latex>, <Latex>$F(1) = 1$</Latex>
                    </li>
                    <li>
                        <b>Recursive Step:</b> <Latex>$F(n) = F(n-1) + F(n-2)$</Latex> for <Latex>$n \geq 2$</Latex>
                    </li>
                </ul>
                <p>
                    <i>Example: To compute <Latex>$F(4)$</Latex>, we have:</i>
                    <ul>
                        <li><Latex>$F(4) = F(3) + F(2)$</Latex></li>
                        <li><Latex>$F(3) = F(2) + F(1)$</Latex></li>
                        <li><Latex>$F(2) = F(1) + F(0)$</Latex></li>
                        <li><Latex>$F(1) = 1$</Latex>, <Latex>$F(0) = 0$</Latex></li>
                    </ul>
                    <p>Thus, <Latex>$F(4) = 3$</Latex>.</p>
                </p>
            </div>
        </div>
    );
};

export default RecursiveDefinitions;
