import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/header.css';

const Header = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    // Navigate to the Learn component with the search term as query parameter
    navigate(`/learn?search=${encodeURIComponent(searchTerm)}`);
  };

  return (
    <div>
      <header className="header">
        <div className="logo">
          <a href="/">SchmitzLearning</a>
        </div>
        <form className="search-form" onSubmit={handleSearchSubmit}>
          <input
            type="text"
            className="search-input left-input"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search"
          />
          <button type="submit" className="search-button">Go</button>
        </form>
        <div>
          {/* <button>
              Sign In
          </button> */}
        </div>
      </header>
      <hr></hr>
    </div>
  );
};

export default Header;
