import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const SumProdRule = () => {
    return (
        <div>
            <h1>Sum & Product Rules</h1>
            <div className='group'>
                <h3>Sum Rule</h3>
                <p>
                    The <span className='emph'>sum rule</span> (or addition principle) states that if there are two disjoint sets of choices, say <Latex>$A$</Latex> and <Latex>$B$</Latex>, then the total number of choices is the sum of the number of choices in each set. 
                </p>
                <p><i>Example: If you can choose a meal between 3 different soups or 2 different salads, the total number of choices is <Latex>$3 + 2 = 5$</Latex>.</i></p>
            </div>
            <div className='group'>
                <h3>Product Rule</h3>
                <p>
                    The <span className='emph'>product rule</span> (or multiplication principle) states that if you have two independent sets of choices, say <Latex>$A$</Latex> and <Latex>$B$</Latex>, the total number of possible outcomes is the product of the number of choices in each set.
                </p>
                <p><i>Example: If you can choose an outfit from 3 different shirts and 2 different pairs of pants, the total number of outfits is <Latex>$3 \times 2 = 6$</Latex>.</i></p>
            </div>
        </div>
    );
};

export default SumProdRule;
